// eslint-disable-next-line
const requestPrecosType = "REQUEST_PRECOSVE";
const receivePrecosType = "RECEIVE_PRECOSVE";
const requestPrecoProdutoType = "REQUEST_PRECOPRODUTO";
const receivePrecoProdutoType = "RECEIVE_PRECOSPRODUTO";
const initialState = { precos: "", isLoading: false };

export const precoVeActions = {
  requestPrecoProduto: (id, campanha, territorio, moeda) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestPrecoProdutoType });

    const url = `/api/precoVendaExterna/${id}/${campanha}/${territorio}/${moeda}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };
    const response = await fetch(url, options);
    const precos = await response.json();

    dispatch({ type: receivePrecoProdutoType, precos });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestPrecosType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receivePrecosType) {
    return {
      ...state,
      precos: action.precos,
      isLoading: false,
    };
  }
  if (action.type === requestPrecoProdutoType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receivePrecoProdutoType) {
    return {
      ...state,
      precos: action.precos,
      isLoading: false,
    };
  }

  return state;
};
