import React from "react";
import TextField from "@mui/material/TextField";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Stack from "@mui/material/Stack";
import { Box, Chip, Collapse, IconButton, InputAdornment, MenuItem, Select, Tooltip } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useFieldArray, useWatch } from "react-hook-form";
import { Controller } from "react-hook-form";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import {
  theme,
  themeCollapse,
  // invertSignal,
  HtmlTooltipTable,
  WatchMaxInput,
  WatchNodeIsolate
} from "./HelpersComponents";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from '@mui/icons-material/Add';
import ConfirmationDialog from "../../components/SelectNewProduto";
import { GetSafeNumber, isNullOrEmpty } from "../../../../../helpers";
import { formatCpfCnpj } from "../../../../../helpers/utils";

function ResumeProdutoTable({ itens, control, getValues, formulario = "formularioCp", ...props }) {
  const [rowHeight, setRowHeight] = React.useState(0);
  const rows = getValues(`${formulario}.cenarioFormularioAccount`);
  const rowRef = React.useCallback(node => {
    if (node !== null) {
      [...node.childNodes].forEach(({ offsetHeight = 0 }) => {
        if (rowHeight < offsetHeight) {
          setRowHeight(() => offsetHeight)
        }
        node.style.height = `${rowHeight}px`;
      })
    }
  }, [rowHeight]);

  return (
    <TableContainer component={Paper}>
      <ThemeProvider theme={(mainTheme) => createTheme({ ...mainTheme, ...theme, palette: { ...mainTheme.palette } })}>
        <Table size="small" aria-label="custom pagination table">
          <TableHead>
            <TableRow sx={{
              "th": {
                whiteSpace: "nowrap",
                fontWeight: "bold"
              },
            }}>
              <TableCell>Produto</TableCell>
              <TableCell align="right">Volume Preenchido</TableCell>
              <TableCell align="right">Volume Restante</TableCell>
              <TableCell align="right">Total Preenchido</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itens.map((row, i) => {
              var total = rows.reduce((total, x) =>
                total += x.cenarioFormularioAccountItens
                  .filter((y) => y.itensCenarioId === row.id)
                  .reduce((sum, y) => sum += y.volume, 0)
                , 0
              );

              var per = (total / row.volume) * 100;
              per = (!isFinite(per) || isNaN(per)) ? 0 : per;

              var restante = (row.volume - total);
              restante = (!isFinite(restante) || isNaN(restante)) ? 0 : restante;

              return (
                <TableRow key={i} ref={rowRef}>
                  <TableCell>
                    {row.produto}
                  </TableCell>
                  <TableCell align="right">
                    {total.toFixed(2).toLocaleString("pt-BR", {
                      style: "currency",
                    })}
                  </TableCell>
                  <TableCell align="right">
                    {restante.toFixed(2).toLocaleString("pt-BR", {
                      style: "currency",
                    })}
                  </TableCell>
                  <TableCell align="right">
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <div>
                        {(per > 100) && <ErrorIcon color="vermelho" />}
                      </div>
                      <div style={{ whiteSpace: "nowrap" }}>
                        {per.toFixed(2).toLocaleString("pt-BR", {
                          style: "currency",
                        })}%
                      </div>
                    </Stack>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </ThemeProvider>
    </TableContainer>
  );
}

function RowResume({ row, itens, currency, municipio, control, formulario = "formularioCp", setValue = (name = "", value) => { }, getValues, ...props }) {
  const cenarioFormularioAccount = useWatch({
    control,
    name: `${formulario}.cenarioFormularioAccount`,
  });
  const allValues = cenarioFormularioAccount?.every(x => !(!x.allValueSame && x.allValueSame !== undefined))
  // const perVolume = cenarioFormularioAccount.reduce((total, x) => (total = total + x.percentual), 0)
  return (
    <TableRow>
      <TableCell colSpan={5} />
      <TableCell>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <div>Percentual Volume Total:</div>

          <div style={{ whiteSpace: "nowrap" }}>
            {/* {perVolume.toLocaleString("pt-BR")}% */}
            <HtmlTooltipTable placement="top"
              title={
                <React.Fragment>
                  <ResumeProdutoTable itens={itens} getValues={getValues} formulario={formulario} />
                </React.Fragment>
              }
            >
              {allValues ? <InfoOutlinedIcon color={"azul"} /> : <ErrorOutlineIcon color={"amarelo"} />}
            </HtmlTooltipTable>
          </div>
        </Stack>
      </TableCell>
      <TableCell />
    </TableRow>
  );
}

function getKeys(formulario, getValues, itensCenarioId, indexA, indexP) {
  const fields = getValues(`${formulario}.cenarioFormularioAccount`);
  // console.log(fields);

  var indexs = [];
  fields.forEach((x, xi) => {
    indexs = indexs.concat(x.cenarioFormularioAccountItens.map((y, yi) => { if (y.itensCenarioId === itensCenarioId && !(xi === indexA && yi === indexP)) { return `${formulario}.cenarioFormularioAccount[${xi}].cenarioFormularioAccountItens[${yi}].key`; } else { return undefined; } }).filter(y => y !== undefined))
  });

  return indexs;
}

function getNames(formulario, getValues, itensCenarioId, indexA, indexP) {
  const fields = getValues(`${formulario}.cenarioFormularioAccount`);
  // console.log(fields);

  var indexs = [];
  fields.forEach((x, xi) => {
    indexs = indexs.concat(x.cenarioFormularioAccountItens.map(
      (y, yi) => {
        if (y.itensCenarioId === itensCenarioId && !(xi === indexA && yi === indexP)) {
          return `${formulario}.cenarioFormularioAccount[${xi}].cenarioFormularioAccountItens[${yi}].volume`;
        }
        else {
          return undefined;
        }
      }
    ).filter(y => y !== undefined))
  });

  return indexs;
}

// function getLength(formulario, getValues) {
//   const fields = getValues(`${formulario}.cenarioFormularioAccount`);
//   // console.log(fields);

//   var indexs = fields.map((x, xi) => `${formulario}.cenarioFormularioAccount[${xi}].cenarioFormularioAccountItens.length`);

//   return indexs;
// }
// function getProdutoSimilar(fields = [], itensCenarioId = 0, indexA = 0, indexP = 0) {
//   let similar = []
//   fields.forEach(((x, xi) => {
//     if (x.itensCenarioId === itensCenarioId && xi !== indexP) {
//       similar.push(`cenarioFormularioAccount[${indexA}].cenarioFormularioAccountItens[${xi}].dataEntregaSol`);
//       similar.push(`cenarioFormularioAccount[${indexA}].cenarioFormularioAccountItens[${xi}].material`);
//     }
//   }));
//   if (similar.length > 0) {
//     similar.push(`cenarioFormularioAccount[${indexA}].cenarioFormularioAccountItens[${indexP}].dataEntregaSol`);
//     similar.push(`cenarioFormularioAccount[${indexA}].cenarioFormularioAccountItens[${indexP}].material`);
//   }
//   // console.log(similar);
//   return similar;
// }

function VolumeInput({ formulario, getValues, itensCenarioId, index, i, InputProps = (names = []) => { }, control, isSeeds, ...props }) {
  const [names, setNames] = React.useState([]);
  const [keys, setKeys] = React.useState([]);
  const [key, setKey] = React.useState("");
  const [lengthChildren, setLengthChildren] = React.useState([]);

  const keysValues = useWatch({
    control,
    name: keys
  });

  const lengthParent = useWatch({
    control,
    name: `${formulario}.cenarioFormularioAccount.length`
  });

  const childrenCount = useWatch({
    control,
    name: lengthChildren
  });

  React.useEffect(
    () => {
      var indexs = [];

      for (var j = 0; j < lengthParent; j++) {
        indexs.push(`${formulario}.cenarioFormularioAccount[${j}].cenarioFormularioAccountItens.length`)
      }

      setLengthChildren(indexs);

      setNames(getNames(formulario, getValues, itensCenarioId, index, i));
      var keysNames = getKeys(formulario, getValues, itensCenarioId, index, i)
      setKeys(keysNames);
      setKey(keysNames.join("-"))
    },
    [formulario, getValues, itensCenarioId, index, i, keysValues, lengthParent, childrenCount]
  )

  return <WatchMaxInput
    key={key}
    control={control}
    names={names}
    InputProps={InputProps(names)}
    {...props}
  />
}

function Row({ formulario = "formularioCp", rows, row, index = 0, itens, isSeeds = false, currency, control, situacaoEspecial = [], setValue = (name = "", value) => { }, getValues = (name = "") => { if (name === undefined) return []; return ""; }, itensToSelect = [], removeRow = () => { }, metodoPagamento, ...props }) {
  const [open, setOpen] = React.useState(false);
  const [rowHeight, setRowHeight] = React.useState(0);
  const { fields, remove, append } = useFieldArray({
    control: control,
    name: `${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens`,
    keyName: 'key'
  });

  const cultura = getValues(`${formulario}.cultura`);
  let vencimento = dayjs(getValues(`${formulario}.dataVencimentoPedido`));
  let inicioVencimento = vencimento;
  let hoje = dayjs();

  if (isSeeds) {
    const metodoType = metodoPagamento?.split('-')[0]?.trim() ?? "";
    if (metodoType === "ZBAN" || metodoType === "ZBAR" || metodoType === "ZBAV") {
      inicioVencimento = hoje > vencimento ? hoje : vencimento;
      vencimento = undefined;
    } else if (metodoType === "ZBPN" || metodoType === "ZBGS" || metodoType === "ZBAP") {
      inicioVencimento = hoje;
    }

    const firstDelivery = getValues(`${formulario}.firstDelivery`);
    const lastDelivery = getValues(`${formulario}.lastDelivery`);

    if (!isNullOrEmpty(firstDelivery)) {
      const firstDeliveryDate = dayjs(firstDelivery);
      inicioVencimento = firstDeliveryDate > inicioVencimento || isNullOrEmpty(inicioVencimento) ? firstDeliveryDate : inicioVencimento;
    }

    if (!isNullOrEmpty(lastDelivery)) {
      const lastDeliveryDate = dayjs(lastDelivery);
      vencimento = vencimento > lastDeliveryDate || isNullOrEmpty(vencimento) ? lastDeliveryDate : vencimento;
    }
  }
  else {
    const metodoPagamento = getValues(`${formulario}.condicoesPagamento`);
    if (metodoPagamento === "CIA") {
      inicioVencimento = hoje > inicioVencimento ? hoje : inicioVencimento;
      vencimento = vencimento.add(360, 'days');
    }
    else {
      inicioVencimento = vencimento.subtract(360, 'days');
      inicioVencimento = hoje > inicioVencimento ? hoje : inicioVencimento;
      vencimento = vencimento.subtract(1, 'days');
    }
  }

  const rowRef = React.useCallback(node => {
    if (node !== null) {
      [...node.childNodes].forEach(({ offsetHeight = 0 }) => {
        if (rowHeight < offsetHeight) {
          setRowHeight(() => offsetHeight)
        }
        node.style.height = `${rowHeight}px`;
      })
    }
  }, [rowHeight]);

  const customOnChangePer = (event) => {
    fields.forEach((item, i) => {
      var row = itens.find(x => x.id === item.itensCenarioId);
      var newValue = row.volume * (event.target.value / 100)
      if ((isSeeds && item.canDesmember) || !isSeeds) {
        setValue(`${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${i}].volume`, newValue);
        setValue(`${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${i}].volumeReal`, null);
      }
    })

    setValue(`${formulario}.cenarioFormularioAccount[${index}].allValueSame`, true);
  }

  const customOnChangeVol = (event) => {
    setValue(event.target.name + 'Real', null);
    var cenarioFormularioAccountItens = getValues(`${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens`);
    var percentual = getValues(`${formulario}.cenarioFormularioAccount[${index}].percentual`);
    var allSame = true;
    cenarioFormularioAccountItens.forEach((item, i) => {
      var row = itens.find(x => x.id === item.itensCenarioId);
      var perProduto = Number((item.volume / row?.volume).toFixed(2)) * 100
      if (perProduto !== percentual) allSame = false;
    })
    setValue(`${formulario}.cenarioFormularioAccount[${index}].allValueSame`, allSame)
  }

  const changedSuffix = (rowIndex) => {
    const formBase = `${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${rowIndex}]`;
    setValue(formBase + ".volume", 0);
  }

  const CalcTotal = (values = []) => {
    return values.reduce((total, item) => {
      var row = itens.find(x => x.id === item.itensCenarioId)
      var valor = (isSeeds ? item.volume : item.volumeReal) * row?.precoNegociado
      return total = total + valor
    }, 0)
  }

  var namesC = [];
  rows.forEach((_, i) => {
    if (index !== i) {
      namesC.push(`${formulario}.cenarioFormularioAccount[${i}].percentual`)
    }
  });

  const culture = (getValues(formulario)?.cultura ?? "").toLowerCase();
  const isMS = isSeeds ? (culture === "milho" || culture === "sorgo") : false;

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset !important' } }}>
        <TableCell sx={{ padding: "6px 6px 6px 6px !important", minWidth: 0, width: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.shipTo?.customerNumber} - {row.account?.cliente}</TableCell>
        <TableCell>{row.account?.municipio}/{row.account?.uf}</TableCell>
        <TableCell>{formatCpfCnpj(row.account?.cpfCnpjDoCliente)}</TableCell>
        <TableCell>
          <Controller
            render={({ field }) =>
              <TextField {...field}
                color={'azul'}
                fullWidth
                multiline
                size="small"
                placeholder="Instruções de entrega"
              />
            }
            name={`${formulario}.cenarioFormularioAccount[${index}].instrucaoEntrega`}
            control={control}
          />
        </TableCell>
        <WatchMaxInput
          align="right"
          placeholder={(max) => `Volume disponível ${max}%`}
          suffix={'%'}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={0}
          customInput={TextField}
          size="small"
          sx={{ input: { textAlign: "right" } }}
          defaultMaxValue={100}
          names={namesC}
          messageError={"Percentual acima do permitido!"}
          allowNegative={false}
          name={`${formulario}.cenarioFormularioAccount[${index}].percentual`}
          fullWidth
          control={control}
          customOnChange={customOnChangePer}
          InputProps={{
            startAdornment: (
              <WatchNodeIsolate position="start"
                Node={InputAdornment}
                control={control}
                valueToWatch={`${formulario}.cenarioFormularioAccount[${index}].allValueSame`}
                textDisplay={(value) => {
                  return (
                    <>
                      {(!value && value !== undefined) && <Tooltip title="Os percentuais podem não ser os mesmos para todos os produtos">
                        <ErrorOutlineIcon color={"amarelo"} />
                      </Tooltip>}
                    </>
                  );
                }}
              />
            ),
          }}
        />
        <WatchNodeIsolate
          align="right"
          control={control}
          valueToWatch={`${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens`}
          calcValue={CalcTotal}
          textDisplay={(value) => value.toLocaleString("pt-BR", {
            style: "currency",
            currency: currency === "BRL" ? "BRL" : "USD",
          })}
        />
        <TableCell align="center">
          <IconButton onClick={removeRow}>
            <DeleteIcon sx={{ color: "#000" }} />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ padding: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <ConfirmationDialog variant="outlined" color={"azul"} startIcon={<AddIcon />} sx={{ marginBottom: '1rem', marginTop: 'calc(1rem - 8px)' }} fullWidth
                options={itensToSelect}
                onChange={(value) => {
                  value = Number(value);
                  const cenarioFormularioItens = getValues(`${formulario}.cenarioFormularioItens`);
                  const cenarioItem = cenarioFormularioItens.find(x => x.itensCenarioId === value)
                  const item = {
                    material: '',
                    volume: 0,
                    dataEntregaSol: null,
                    dataFaturamento: null,
                    instrucaoEntrega: '',
                    blocoCondicaoEspecialEntrega: [],
                    condicaoEspecialEntrega: [],
                    itensCenarioId: value,
                    materiais: cenarioItem?.materiais ?? []
                  };
                  append(item);
                }}>
                Adicionar Produto
              </ConfirmationDialog>
              <ThemeProvider theme={(mainTheme) => createTheme({ ...mainTheme, ...theme, palette: { ...mainTheme.palette } })}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="custom pagination table">
                  <TableHead>
                    <TableRow
                      sx={{
                        "th": {
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                          color: "#fff"
                        },
                        backgroundColor: "var(--cort-blue)",
                        color: "#fff"
                      }}>
                      <TableCell>Produto</TableCell>
                      {!isSeeds ?
                        <TableCell>Material</TableCell> :
                        <TableCell>Sufixo</TableCell>
                      }
                      <TableCell align="right">Volume</TableCell>
                      {!isSeeds && <>
                        <TableCell>Embalagem (cx/sc)</TableCell>
                        <TableCell>Volume Real</TableCell>
                      </>
                      }
                      <TableCell>Data de Entrega</TableCell>
                      {/* <TableCell>Data de Faturamento</TableCell>
                      <TableCell>Instruções de entrega</TableCell>
                      <TableCell>Situação Especial</TableCell> */}
                      <TableCell align="right">Preço Negociado</TableCell>
                      <TableCell align="right">Valor Final</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields?.map((item, i) => {
                      const row = itens.find(x => x.id === item.itensCenarioId)
                      const cantDesmember = isSeeds && item.canDesmember === false;

                      // const names = getNames(formulario, getValues, item.itensCenarioId, index, i);
                      // const similar = getProdutoSimilar(fields, item.itensCenarioId, index, i);
                      // var descontopack = (row.preco + (row.preco * row.encargos)) * (row.descontoPacks / 100)
                      return (
                        <TableRow key={item.key} ref={rowRef}>
                          <TableCell>
                            {row?.produto}
                          </TableCell>
                          <TableCell>
                            <Controller
                              render={({ field }) => (
                                <Select {...field}
                                  fullWidth
                                  size="small"
                                  onChange={(e) => { field.onChange(e); changedSuffix(i); }}
                                  disabled={cantDesmember}>
                                  {!isSeeds ?
                                    item.materiais.map((material) => (
                                      <MenuItem key={material.materialId} value={material.materialId} >
                                        {material.materialName}
                                      </MenuItem>
                                    ))
                                    :
                                    item.materiais.map((material) => (
                                      <MenuItem key={material.id} value={material.id} >
                                        {material.sufixo}
                                      </MenuItem>
                                    ))
                                  }
                                </Select>
                              )}
                              name={`${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${i}].material`}
                              control={control}
                            />
                          </TableCell>
                          <WatchNodeIsolate
                            valueToWatch={`${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${i}].material`}
                            Node={React.Fragment}
                            control={control}
                            textDisplay={(value) => {
                              const material = item.materiais.find(x => x.id === value);

                              const conversion = material?.conversao === 0 ? 1 : material?.conversao;
                              const seedsMaxVolume = isMS ? (conversion === 1 ? 1500 : 30) : (conversion === 1 ? 2040 : 20);
                              const volumeMaxValue = isSeeds ? seedsMaxVolume : row?.volume;

                              return <VolumeInput
                                formulario={formulario}
                                getValues={getValues}
                                itensCenarioId={item.itensCenarioId}
                                index={index}
                                i={i}
                                align="right"
                                fullWidth
                                placeholder={(max) => `Volume disponível ${max}`}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                customInput={TextField}
                                size="small"
                                sx={{ input: { textAlign: "right" } }}
                                defaultMaxValue={volumeMaxValue}
                                messageError={"Volume acima do permitido!"}
                                allowNegative={false}
                                name={`${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${i}].volume`}
                                customOnChange={customOnChangeVol}
                                control={control}
                                disabled={cantDesmember}
                                InputProps={(names) => {
                                  return {
                                    startAdornment: (
                                      <WatchNodeIsolate position="start"
                                        Node={InputAdornment}
                                        control={control}
                                        valueToWatch={[...names, `${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${i}].volume`]}
                                        textDisplay={() => {
                                          const item = getValues(`${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${i}]`);
                                          const itens = getValues(`${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens`);
                                          const sameProduct = itens.filter(x => x.material === item.material);

                                          var total = sameProduct.reduce((total, x) => { return total + x.volume }, 0)
                                          if (!isFinite(total) || isNaN(total)) total = 0;
                                          return (
                                            <>
                                              {(total > volumeMaxValue) &&
                                                <Tooltip title="Volume total acima do permitido">
                                                  <ErrorIcon color={"vermelho"} />
                                                </Tooltip>
                                              }
                                              {(total > material?.aCumprir) &&
                                                <Tooltip title="Volume total acima do volume a cumprir">
                                                  <ErrorIcon color={"vermelho"} />
                                                </Tooltip>
                                              }
                                            </>
                                          );
                                        }}
                                      />
                                    ),
                                  }
                                }}
                              />
                            }}
                          />

                          {!isSeeds && <>
                            <WatchNodeIsolate
                              align="right"
                              control={control}
                              valueToWatch={[`${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${i}].material`,
                              `${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${i}].volume`,
                              `${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${i}].volumeReal`]}
                              textDisplay={(value) => {
                                // eslint-disable-next-line eqeqeq
                                var produto = item.materiais.find(x => x.materialId == value[0])
                                var qtd = 0;
                                if (produto != null && produto !== undefined && value[2] == null) {
                                  qtd = value[1] / produto.embalagem;
                                } else if (produto != null && produto !== undefined && value[2] != null) {
                                  qtd = value[2] / produto.embalagem;
                                }
                                if (Number.isInteger(qtd)) {
                                  if (value[2] == null) {
                                    setValue(`${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${i}].volumeReal`, value[1])
                                    setValue(`${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${i}].qtdPedido`, qtd)
                                  }
                                  return qtd;
                                }
                                else {
                                  var qtdPedido = getValues(`${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${i}].qtdPedido`)
                                  if (qtdPedido !== 0) {
                                    var newVolume = GetSafeNumber(Math.floor(qtd) * produto.embalagem);
                                    setValue(`${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${i}].volumeReal`, newVolume)
                                    setValue(`${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${i}].qtdPedido`, Math.floor(qtd))
                                  }
                                  return <>
                                    <Chip label={GetSafeNumber(Math.floor(qtd))} variant="outlined" color="azul" onClick={(event) => {
                                      if (produto != null && produto !== undefined) {
                                        var newVolume = GetSafeNumber(Math.floor(qtd) * produto.embalagem);
                                        setValue(`${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${i}].volumeReal`, newVolume)
                                        setValue(`${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${i}].qtdPedido`, Math.floor(qtd))
                                      }
                                    }} /> ou <Chip label={GetSafeNumber(Math.ceil(qtd))} variant="outlined" color="azul" onClick={(event) => {
                                      if (produto != null && produto !== undefined) {
                                        var newVolume = GetSafeNumber(Math.ceil(qtd) * produto.embalagem);
                                        setValue(`${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${i}].volumeReal`, newVolume)
                                        setValue(`${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${i}].qtdPedido`, Math.ceil(qtd))
                                      }
                                    }} />
                                  </>;
                                }
                              }
                              }
                            />
                            <WatchNodeIsolate
                              align="right"
                              control={control}
                              valueToWatch={`${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${i}].volumeReal`}
                              textDisplay={(value) => {
                                return (
                                  <>
                                    {GetSafeNumber(value, 2).toLocaleString("pt-BR")}
                                  </>
                                );
                              }}
                            />
                          </>
                          }
                          <TableCell>
                            <Controller
                              render={({ field }) => {
                                const { value, ...otherProps } = field;
                                return <DatePicker {...otherProps}
                                  value={dayjs(value)}
                                  color={'azul'}
                                  fullWidth
                                  slotProps={{ field: { size: "small", placeholder: "DD/MM/AAAA" } }}
                                  maxDate={vencimento}
                                  minDate={inicioVencimento}
                                  disabled={cantDesmember}
                                  shouldDisableDate={(date) => {
                                    if (!isSeeds) {
                                      return false;
                                    }
                                    if (cultura === "Sorgo" || cultura === "Milho") {
                                      if ((date.date() % 15) === 0 || (date.daysInMonth() < 30 && date.date() === date.daysInMonth())) {
                                        if (hoje.month() === date.month()) {
                                          if (hoje.date() <= 15 && date.date() <= 15) {
                                            return true;
                                          } else if (hoje.date() > 15 && hoje.date() <= 30 && date.date() > 15) {
                                            return true;
                                          }
                                        }
                                        return false;
                                      }
                                    } else {
                                      if ((date.date() % 10) === 0 || (date.daysInMonth() < 30 && date.date() === date.daysInMonth())) {
                                        return false;
                                      }
                                    }
                                    return true;
                                  }}
                                />
                              }
                              }
                              name={`${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${i}].dataEntregaSol`}
                              control={control}
                            />
                          </TableCell>
                          <TableCell align="right">
                            {
                              row?.precoNegociado?.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: (currency === "BRL" ? "BRL" : "USD"),
                              })
                            }
                          </TableCell>
                          <WatchNodeIsolate
                            align="right"
                            control={control}
                            valueToWatch={isSeeds ?
                              `${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${i}].volume` :
                              `${formulario}.cenarioFormularioAccount[${index}].cenarioFormularioAccountItens[${i}].volumeReal`}
                            calcValue={(value) => { return value * row?.precoNegociado }}
                            textDisplay={(value) => {
                              return (
                                <>
                                  {value?.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: currency === "BRL" ? "BRL" : "USD",
                                  })}
                                </>
                              );
                            }}
                          />
                          <TableCell align="center">
                            <IconButton onClick={(e) => { remove(i) }}>
                              <DeleteIcon sx={{ color: "#000" }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </ThemeProvider>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment >
  );
}

export function TableClientes({ rows: rowsBase, itens = [], formulario = "formularioCp", currency, isSeeds = false, setValue, getValues, control, situacaoEspecial = [], metodoPagamento }) {
  const itensToSelect = itens.map(x => { return { label: x.produto, value: x.id } })
  const { fields: rows, remove, append } = useFieldArray({
    control: control, // control props comes from useForm (optional: if you are using FormContext)
    name: `${formulario}.cenarioFormularioAccount`, // unique name for your Field Array
    keyName: 'newKey'
  });

  return (
    <Box>
      <ConfirmationDialog variant="outlined" color={"azul"} startIcon={<AddIcon />} sx={{ marginBottom: '1rem', marginTop: 'calc(1rem - 8px)' }} fullWidth
        options={
          rowsBase.map((row) => {
            return {
              value: row.key,
              label: `${row.shipTo.customerNumber} - ${row.account.cliente}\nEnviar para: ${row.shipTo.customerBusinessPartnerNumber} - ${row.shipTo.businessPartnerCustomerDescr}\n IE: ${row.account.stateRegistration} | ${row.account?.municipio} - ${row.account?.uf}`
            }
          })
        }
        onChange={(value) => {
          const item = rowsBase.find(x => x.key === value);
          const newItem = { ...item };

          append(newItem)
        }}
        title="Clientes"
      >
        Adicionar Cliente
      </ConfirmationDialog>
      <TableContainer component={Paper}>

        <ThemeProvider theme={(mainTheme) => createTheme({ ...mainTheme, ...themeCollapse, palette: { ...mainTheme.palette } })}>
          <Table aria-label="collapsible table">
            <TableHead >
              <TableRow sx={{
                "th": {
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                  color: "#fff"
                },
                backgroundColor: "var(--cort-blue)",
                color: "#fff"
              }}>
                <TableCell sx={{ padding: "6px 6px 6px 6px !important", minWidth: "0" }} />
                <TableCell>Cliente</TableCell>
                <TableCell>Município/UF</TableCell>
                <TableCell>CPF/CNPJ</TableCell>
                <TableCell>Instruções de entrega</TableCell>
                <TableCell align="right">Percentual Volume %</TableCell>
                <TableCell align="right">Valor Final</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, i) => {
                const removeRow = () => { remove(i) }
                return (
                  <Row key={row.newKey}
                    index={i}
                    row={row}
                    itens={itens}
                    currency={currency}
                    control={control}
                    setValue={setValue}
                    rows={rows}
                    isSeeds={isSeeds}
                    getValues={getValues}
                    situacaoEspecial={situacaoEspecial}
                    itensToSelect={itensToSelect}
                    formulario={formulario}
                    removeRow={removeRow}
                    metodoPagamento={metodoPagamento}
                  />
                )
              })}
              <RowResume
                itens={itens}
                currency={currency}
                control={control}
                setValue={setValue}
                getValues={getValues}
                formulario={formulario}
              />
            </TableBody>
          </Table>
        </ThemeProvider>
      </TableContainer>
    </Box>
  );
}