const requestCampaigns = "REQUEST_CAMPAIGNS_VE";
const receiveCampaigns = "RECEIVE_CAMPAIGNS_VE";

const requestCampaign = "REQUEST_CAMPAIGN_VE";
const receiveCampaign = "RECEIVE_CAMPAIGN_VE";

const requestUpdateCampaign = "REQUEST_UPDATE_CAMPAIGN_VE";
const receiveUpdateCamapign = "RECEIVE_UPDATE_CAMPAIGN_VE";

const requestDeleteCampaign = "REQUEST_DELETE_CAMPAIGN_VE";

const clearCampaignStore = "CLEAR_CAMPAIGN_STORE";

const initialState = { campaigns: [], campaign: null, isLoading: false };
const apiBase = "api/SimuladorVendaExterna/CampanhasVendaExterna";

export const adminCampaignVeActions = {
  list: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestCampaigns });

    const url = `${apiBase}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const campaigns = await response.json();

    dispatch({ type: receiveCampaigns, campaigns });
  },
  get: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestCampaign });

    const url = `${apiBase}/${id}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const campaign = await response.json();

    dispatch({ type: receiveCampaign, campaign });
  },
  update: (id, form, navigate) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestUpdateCampaign });

    const url = `${apiBase}/${id}`;

    const options = {
      method: "POST",
      body: JSON.stringify(form),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      navigate('/Admin-CampaignVe');
      dispatch({ type: receiveUpdateCamapign, campaign: null });
      dispatch(adminCampaignVeActions.list());
    }
  },
  delete: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestDeleteCampaign });

    const url = `${apiBase}/${id}`;

    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      dispatch(adminCampaignVeActions.list());
    }
  },
  clear: () => async (dispatch) => {
    dispatch({ type: clearCampaignStore });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case requestCampaigns:
      return {
        ...state,
        isLoading: true,
      };
    case receiveCampaigns:
      return {
        ...state,
        isLoading: false,
        campaigns: action.campaigns
      };
    case requestCampaign:
      return {
        ...state,
        isLoading: true,
      };
    case receiveCampaign:
      return {
        ...state,
        isLoading: false,
        campaign: action.campaign
      }
    case clearCampaignStore:
      return {
        ...state,
        campaign: undefined,
        campaigns: [],
      };
    default:
      return state;
  }
};
