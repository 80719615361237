import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Dialog, DialogActions, DialogTitle, Grid, IconButton, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import AllowUseNavigate, { navigate } from '../../../../Navigate';
import { adminSimuladorVeActions } from '../../../../../actions/admin/simuladorVe.actions';
import AddIcon from '@mui/icons-material/Add';
import { adminTerritoryActions } from '../../../../../actions/admin/territory.actions';
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

const NumericFormatCustom = React.forwardRef(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        valueIsNumericString
      />
    );
  },
);

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
class AdminSimuladorCreateFornecedorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fornecedor: "",
      grupoMaterial: "",
      areaComercial: "",
      distrito: "",
      territorio: "",
      produto: "",
      precoMinimo: "",
      precoSugerido: "",
      prazoEntrega: "",
      moeda: "",
      addedItems: [],
      produtos: [],
      open: false,
      openExist: false,
      currency: "",
      isLoading: false,
      page: 0,
      rowsPerPage: 10,
      mensagem: "",
      botao: "",
    };

    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fetchProdutos = this.fetchProdutos.bind(this);
    this.saveCenario = this.saveCenario.bind(this);
    this.checkFornecedorGrupoMaterialExists = this.checkFornecedorGrupoMaterialExists.bind(this);
  }

  componentWillMount() {
    this.props.actions.requestCampanhas();
    this.props.actions.requestFornecedores();
    this.props.actions.requestGrupoMateriais();
    this.props.actions.requestTerritorios();
    this.props.actions.requestDisponilidades();
  }

  handleChangePage(event, newPage) {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });

    if (name === 'campanha') {
      const fornecedor = this.props.adminSimuladorVe?.fornecedores?.[0]?.id;
      const grupoMaterial = this.props.adminSimuladorVe?.grupoMaterial?.[0]?.id;
      this.setState({ fornecedor: fornecedor, grupoMaterial: grupoMaterial });

      this.fetchProdutos(fornecedor, grupoMaterial);
      this.checkFornecedorGrupoMaterialExists(fornecedor, grupoMaterial, value);
    }
    else if (name === 'areaComercial') {
      this.setState({ distrito: '', territorio: '' });
    }
    else if (name === 'distrito') {
      this.setState({ territorio: '' });
    }
  }

  fetchProdutos(fornecedor, grupoMaterial) {
    if (fornecedor && grupoMaterial) {
      this.props.actions.requestProdutosByFornecedorGrupo(fornecedor, grupoMaterial)
        .then((produtos) => {
          this.setState({ produtos });
        });
    } else {
      this.setState({ produtos: [] });
    }
  }

  checkFornecedorGrupoMaterialExists(fornecedor, grupoMaterial, campanha) {
    if (fornecedor && grupoMaterial) {
      const disponibilidades = this.props.adminSimuladorVe.disponibilidades;
      const existe = disponibilidades.some(d =>
        d.fornecedorId === fornecedor && d.grupoMaterialId === grupoMaterial && d.campanhaId === campanha
      );

      if (existe) {
        this.setState({
          fornecedor: "",
          grupoMaterial: "",
          campanha: "",
          openExist: true,
        });
      }
    }
  }

  handleAddItem = () => {
    this.setState({ isLoading: true });
    const { areaComercial, distrito, territorio, produto, precoMinimo, precoSugerido, moeda, prazoEntrega } = this.state;
    const produtos = this.props.adminSimuladorVe.produtos ? this.props.adminSimuladorVe.produtos : [];
    const rows = [...this.props.adminSimuladorVe.rows];

    if (!(rows.find((x) => this.state.produto.id === x.produto.id))) {
      this.props.actions.addRow(
        produto,
        produtos,
        areaComercial,
        distrito,
        territorio,
        precoMinimo,
        precoSugerido,
        moeda,
        prazoEntrega
      );
    }

    this.setState({ isLoading: false });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.setState({ openExist: false });
  };

  handleDeleteRow = (index) => {
    this.props.actions.deleteRow(index);
    this.handleClose();
  };

  saveCenario = (event) => {
    event.preventDefault();
    const rows = [...this.props.adminSimuladorVe.rows];
    this.props.actions.createSaveDisponibilidade(
      this.state.campanha,
      this.state.fornecedor,
      this.state.grupoMaterial,
      rows,
      navigate
    ).then(() => {
      this.props.actions.removeAllRows();
    })
  };

  render() {
    const rows = this.props.adminSimuladorVe.rows ? this.props.adminSimuladorVe.rows : [];
    const isLoading = this.props.adminSimuladorVe.isLoadingFornecedor;

    const campanhas = this.props.adminSimuladorVe?.campanhas ? this.props.adminSimuladorVe.campanhas : [];
    const territorios = this.props.adminTerritory?.territorios ? this.props.adminTerritory.territorios : [];
    const produtos = this.props.adminSimuladorVe?.produtos ? this.props.adminSimuladorVe.produtos : [];

    const areaComercialSet = new Set(territorios
      .filter(x => x.areaName !== "")
      .map(x => x.areaName)
    );
    const distritoSet = new Set(
      territorios
        .filter(x => x.areaName === this.state.areaComercial && x.districtName !== "")
        .map(x => x.districtName)
    );
    const territorioSet = new Set(
      territorios
        .filter(x => x.areaName === this.state.areaComercial && x.districtName === this.state.distrito && x.territoryName !== "")
        .map(x => x.territoryName)
    );

    return (
      <div className="card" style={{ borderRadius: 0 }}>
        <AllowUseNavigate />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading || this.state.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={"/Admin-Precos"}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/Admin-Precos"}>
                Preços
              </Link>
              <Typography color="text.primary">Novo Preço</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <form
            onSubmit={this.saveCenario}
            autoComplete="off"
          >
            <Grid direction="row" container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <label className="d-block">Campanha</label>
                <Select
                  name="campanha"
                  label="Campanha"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={this.state.campanha || ""}
                  onChange={this.handleChange}
                >
                  {
                    campanhas.map((x, index) => (
                      <MenuItem key={index} value={x.id}>{x.nome}</MenuItem>
                    ))
                  }
                </Select>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <label className="d-block">Área Comercial</label>
                <Select
                  name='areaComercial'
                  value={this.state.areaComercial || ""}
                  placeholder="Área Comercial"
                  onChange={this.handleChange}
                  fullWidth
                  size="small"
                >
                  {Array.from(areaComercialSet).map((x) => (
                    <MenuItem value={x}>{x}</MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <label>Distrito</label>
                <Select
                  name='distrito'
                  value={this.state.distrito || ""}
                  placeholder="Distrito"
                  onChange={this.handleChange}
                  fullWidth
                  size="small"
                >
                  {Array.from(distritoSet).map((x) => (
                    <MenuItem value={x}>{x}</MenuItem>
                  ))}
                  <MenuItem key={"removeDistrict"} value={null}>Nenhum</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <label>Território</label>
                <Select
                  name='territorio'
                  value={this.state.territorio || ""}
                  placeholder="Território"
                  onChange={this.handleChange}
                  fullWidth
                  size="small"
                >
                  {Array.from(territorioSet).map((x) => (
                    <MenuItem key={x} value={x}>{x}</MenuItem>
                  ))}
                  <MenuItem key={"removeTerritory"} value={null}>Nenhum</MenuItem>
                </Select>
              </Grid>

              <Grid item md={12} sx={{ fontWeight: "bolder" }}>
                Selecione os parâmetros abaixo:
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <label>Produto</label>
                <Select
                  name='produto'
                  value={this.state.produto || ""}
                  placeholder="Produto"
                  onChange={this.handleChange}
                  fullWidth
                  size="small"
                >
                  {produtos.map((x, index) => (
                    <MenuItem key={index} value={x.id}>{x.nome}</MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <label>Moeda</label>
                <Select
                  name='moeda'
                  value={this.state.moeda || ""}
                  placeholder="Moeda"
                  onChange={this.handleChange}
                  fullWidth
                  size="small"
                >
                  <MenuItem value="BRL">BRL</MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <label>Preço Mínimo</label>
                <TextField
                  name='precoMinimo'
                  value={this.state.precoMinimo || ""}
                  onChange={this.handleChange}
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                />
              </Grid>

              <Grid item xs={10} sm={10} md={2}>
                <label>Preço Sugerido</label>
                <TextField
                  name='precoSugerido'
                  value={this.state.precoSugerido || ""}
                  onChange={this.handleChange}
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                />
              </Grid>

              <Grid item xs={10} sm={10} md={2.5}>
                <label>Prazo de Entrega (Dias)</label>
                <TextField
                  name='prazoEntrega'
                  value={this.state.prazoEntrega || ""}
                  onChange={this.handleChange}
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                />
              </Grid>

              <Grid item xs={2} sm={2} md={0.5} sx={{ alignContent: "end" }}>
                <IconButton
                  color="primary"
                  size="large"
                  aria-label="Adicionar"
                  fullWidth
                  onClick={this.handleAddItem}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
            {rows.length > 0 && (
              <TableContainer component={Paper} sx={{ marginTop: 4 }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Área Comercial</TableCell>
                      <TableCell>Distrito</TableCell>
                      <TableCell>Território</TableCell>
                      <TableCell>Produto</TableCell>
                      <TableCell>Moeda</TableCell>
                      <TableCell>Preço Mínimo</TableCell>
                      <TableCell>Preço Sugerido</TableCell>
                      <TableCell>Prazo de Entrega (Dias)</TableCell>
                      <TableCell align="center">Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.areaComercial}</TableCell>
                        <TableCell>{row.distrito}</TableCell>
                        <TableCell>{row.territorio}</TableCell>
                        <TableCell>{row.produto.nome}</TableCell>
                        <TableCell>{row.moeda}</TableCell>
                        <TableCell>
                          {Number(row.precoMinimo).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: (row.moeda === "BRL" ? "BRL" : "USD"),
                          })}
                        </TableCell>
                        <TableCell>
                          {Number(row.precoSugerido).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: (row.moeda === "BRL" ? "BRL" : "USD"),
                          })}
                        </TableCell>
                        <TableCell>
                          {Number(row.prazoEntrega)}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton aria-label="delete" size="small" onClick={this.handleClickOpen}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                          <Dialog
                            open={this.state.open}
                            onClose={this.handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">
                              Deseja realmente exluir este item?
                            </DialogTitle>
                            <DialogActions>
                              <Button onClick={this.handleClose}>Cancelar</Button>
                              <Button variant="contained" onClick={() => this.handleDeleteRow(index)} autoFocus>
                                Exluir
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  labelRowsPerPage="Linhas por página:"
                  component="div"
                  count={rows.length}
                  page={this.state.page}
                  onPageChange={this.handleChangePage}
                  rowsPerPage={this.state.rowsPerPage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage} />
              </TableContainer>
            )}
            {this.state.openExist && (
              <Dialog
                open={this.state.openExist}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  Fornecedor e Grupo Material já existem em Preços. Altere pelo menos um para adicionar itens.
                </DialogTitle>
                <DialogActions>
                  <Button onClick={this.handleClose}>Ok</Button>
                </DialogActions>
              </Dialog>
            )}
            {rows.length > 0 && (
              <div>
                <Button sx={{ marginTop: "10px" }} variant="contained" color="primary" fullWidth type="submit">
                  Salvar
                </Button>

              </div>
            )}

          </form>

        </div>
      </div>
    );
  }

}
export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...adminTerritoryActions,
        ...adminSimuladorVeActions,
      }, dispatch),
    };
  }
)(AdminSimuladorCreateFornecedorPage);
