import { WebStorageStateStore } from "oidc-client";
import { createUserManager } from "redux-oidc";

// Destructure environment variables for cleaner access
const { REACT_APP_CLIENTID, REACT_APP_TENANTID, REACT_APP_RESOURCEID } = process.env;

// Utility function to construct base URI
const getBaseUri = () => `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`;

// Construct user manager configuration
const userManagerConfig = {
  authority: `https://login.microsoftonline.com/${REACT_APP_TENANTID}/.well-known/openid-configuration`,
  client_id: REACT_APP_CLIENTID,
  redirect_uri: `${getBaseUri()}/callback`,
  post_logout_redirect_uri: getBaseUri(),
  automaticSilentRenew: false,
  filterProtocolClaims: true,
  loadUserInfo: false,
  scope: "openid profile",
  response_type: "token id_token",
  extraQueryParams: { resource: REACT_APP_RESOURCEID },
  userStore: new WebStorageStateStore({ store: localStorage }),
};

// Create and export the user manager instance
const userManager = createUserManager(userManagerConfig);

export default userManager;