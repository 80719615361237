import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import SearchIcon from '@mui/icons-material/Search';
import { Stack } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { clientTerritoryActions } from "../../../actions/clientTerritory.actions";
import { userActions } from "../../../actions/user.actions";
import { ReactComponent as SimuladorDDSSvg } from "../../../assets/svg/SimuladorDDS.svg";
import ButtonSelectTerrritory from '../../ButtonSelectTerrritory';
import { Search, SearchIconWrapper, StyledInputBase } from "../../Search";
import EditIcon from '@mui/icons-material/Edit';
import { clientActions } from '../../../actions/client.actions';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'cpfCnpj',
    label: 'CPF/CNPJ',
    sortable: false
  },
  {
    id: 'acoes',
    align: 'center',
    label: 'Ações disponíveis',
    sortable: false
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, setFilter } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const handleChangeFilter = (e) => {
    setFilter(e.target.value.toLowerCase())
  }

  return (
    <TableHead
      sx={{
        backgroundColor: "#0072CE",
        "th": {
          color: "#FFF",
          fontWeight: "bold"
        },
      }}>
      <TableRow>
        <TableCell
          key={'account'}
          align={'left'}
          sortDirection={orderBy === 'account' ? order : false}
          size="medium"
          sx={{
            '*': {
              color: '#fff !important',
              '&:hover': {
                color: '#FFF !important',
              },
            },
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={'auto'} sx={{ alignSelf: "center" }}>
              Account
            </Grid>
            <Grid item xs >
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Deseja pesquisar algum cliente (Account)?"
                  sx={{ width: '100%' }}
                  inputProps={{ 'aria-label': 'Deseja pesquisar algum cliente (Account)?' }}
                  onChange={handleChangeFilter}
                />
              </Search>
            </Grid>
          </Grid>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
            size="medium"
            sx={{
              '*': {
                color: '#fff !important',
                '&:hover': {
                  color: '#FFF !important',
                },
              },
            }}
          >
            {headCell.sortable ?
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}

              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
              : headCell.label
            }

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />

      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />

      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

function defaultLabelDisplayedRows({ from, to, count }) { return `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`; }


const setRowsFilterWithTerritorio = (rowsToFilter, territorios, order, orderBy) => {
  var rows = []
  if (territorios.length > 1) {
    territorios.forEach(t => {
      var rowsT = rowsToFilter.filter(x => x.territory === t.territoryName)
      if (rowsT.length > 0) {
        t.isCabecalho = true;
        rows.push(t);
        rows = rows.concat(stableSort(rowsT, getComparator(order, orderBy)));
      }
    });

    var countT = rows.filter(x => x.isCabecalho !== undefined && x.isCabecalho)
    if (countT.length < 1) {
      rows = stableSort(rowsToFilter, getComparator(order, orderBy));
    }
  } else {
    rows = stableSort(rowsToFilter, getComparator(order, orderBy));
  }

  return rows
}

function CustomPaginationActionsTable(props) {
  const { rows, oidc, territorios, role } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [order, setOrder] = React.useState('desc');
  const [filter, setFilter] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('probBuy2MoreProducts');
  const [constRows, setConstRows] = React.useState(rows);
  const [isSet, setIsSet] = React.useState(false);
  const [rowsF, setRowsF] = React.useState([]);

  // eslint-disable-next-line eqeqeq
  if (!isSet && constRows == rows) {
    setRowsF(setRowsFilterWithTerritorio(constRows.filter(x => x.account.nome.toLowerCase().includes(filter)), territorios, order, orderBy))
    setIsSet(true);
  }

  // eslint-disable-next-line eqeqeq
  if (constRows != rows) {
    setConstRows(rows)
    // console.log("Rows: ", rows.account);
    setRowsF(setRowsFilterWithTerritorio(rows.filter(x => x.account.nome.toLowerCase().includes(filter)), territorios, order, orderBy))
    setPage(0);
  }

  const handleChangeFilter = (value) => {
    setFilter(value);
    setRowsF(setRowsFilterWithTerritorio(constRows.filter(x => x.account.nome.toLowerCase().includes(value)), territorios, order, orderBy));
    setPage(0);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setRowsF(setRowsFilterWithTerritorio(constRows.filter(x => x.account.toLowerCase().includes(filter)), territorios, isAsc ? 'desc' : 'asc', property));
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="custom pagination table">
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          setFilter={handleChangeFilter}
        />
        <TableBody>
          {(rowsPerPage > 0
            ? rowsF.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rowsF
          ).map((row) => {
          //  console.log("row:", row);
            return ((row.isCabecalho === undefined || !row.isCabecalho) ?
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" sx={{ width: "40%" }} >
                  {row.account.nome}
                </TableCell>
                <TableCell component="th" scope="row" sx={{ width: "40%" }} >
                  {row.account.cpfCnpj}
                </TableCell>
                <TableCell align="center">
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 1.5, md: 2 }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Tooltip title="Pedidos">
                      <Link to={`/SimuladorVe/MinhasSimulacoesCliente?GroupId=${row.id}`}>
                        <SimuladorDDSSvg height={35} />
                      </Link>
                    </Tooltip>
                  </Stack>
                </TableCell>
              </TableRow>
              :
              <TableRow
                key={row.territoryId}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: 53, backgroundColor: "var(--bs-card-cap-bg)" }}
              >
                <TableCell colSpan={6}>
                  <h4 className="m-0">
                    Território {row.territorioCode}
                  </h4>
                </TableCell>
              </TableRow>
            )
          })}
          {rowsF.length === 0 && filter !== '' &&
            <TableRow sx={{ height: 53 }}>
              <TableCell colSpan={6} align="center" >
                Account não encontrada
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
      <TablePagination
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={defaultLabelDisplayedRows}
        component="div"
        rowsPerPageOptions={[5, 10, 25]}
        colSpan={3}
        count={rowsF.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            'aria-label': 'Linhas por página',
          },
          native: false,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </TableContainer>

  );
}

class SimulatorHomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      territorio: undefined,
      area: undefined,
      distrito: undefined,
      isSetStorage: false
    };

    this.onChangeTerritorio = this.onChangeTerritorio.bind(this);
    this.setByStorage = this.setByStorage.bind(this);
    this.onChangeArea = this.onChangeArea.bind(this);
    this.onChangeDistrito = this.onChangeDistrito.bind(this);
    this.onChangeTerritorio = this.onChangeTerritorio.bind(this);
  }

  componentWillMount() {
    this.props.actions.requestClientTerritories();
    this.props.actions.requestTerritorios();
    this.props.actions.requestRole();
    this.props.actions.requestRoleLevels();
  }

  onChangeArea(event, newInputValue, options) {
    if (newInputValue != null) {
      this.setState({ area: newInputValue });
      this.setState({ distrito: undefined });
      this.setState({ territorio: undefined });
      localStorage.setItem("Area", JSON.stringify(newInputValue));
      localStorage.removeItem("Distrito");
      localStorage.removeItem("Territorio");
    }
    else {
      this.setState({ area: options[0] });
      localStorage.setItem("Area", JSON.stringify(options[0]));
    }
  }

  onChangeDistrito(event, newInputValue, options) {
    if (newInputValue != null) {
      this.setState({ distrito: newInputValue });
      this.setState({ territorio: undefined });
      localStorage.setItem("Distrito", JSON.stringify(newInputValue));
      localStorage.removeItem("Territorio");

    }
    else {
      this.setState({ distrito: options[0] });
      localStorage.setItem("Distrito", JSON.stringify(options[0]));
    }

  }

  onChangeTerritorio(event, newInputValue, options) {
    if (newInputValue != null) {
      this.setState({ territorio: newInputValue });
      localStorage.setItem("Territorio", JSON.stringify(newInputValue));
      this.props.actions.changeTerritorio(newInputValue.territoryId);
    }
    else {
      this.setState({ territorio: options[0] });
      localStorage.setItem("Territorio", JSON.stringify(options[0]));
      this.props.actions.changeTerritorio(options[0].territoryId);
    }

  }

  setByStorage(areas = [], distritos = [], territorios = []) {
    var area = JSON.parse(localStorage.getItem("Area"))
    var distrito = JSON.parse(localStorage.getItem("Distrito"))
    var territorio = JSON.parse(localStorage.getItem("Territorio"))
    if (area && areas.find(x => x === area) !== undefined) {
      this.setState({ area: area });
    } else {
      localStorage.removeItem("Area")
    }
    if (distrito && distritos.find(x => x.districtName === distrito.districtName) !== undefined) {
      this.setState({ distrito: distrito });
    } else {
      localStorage.removeItem("Distrito")
    }
    if (territorio && territorios.find(x => x.territoryName === territorio.territoryName) !== undefined) {
      this.setState({ territorio: territorio });
    } else {
      localStorage.removeItem("Territorio")
    }

    this.setState({ isSetStorage: true });
  }

  render() {
    const { oidc } = this.props;

    var rows = [];
    var territorio = "";
    var role = "";
    var roleLevels = null;
    var territorios = [{ territoryName: "TODOS" }];
    var areas = ["TODOS"];
    var distritos = [{ districtName: "TODOS" }];

    if (!this.props.user.isLoadingRole && this.props.user.role != null && !this.props.user.isLoadingRoleLevels && this.props.user.roleLevels != null) {
      roleLevels = this.props.user.roleLevels
      role = this.props.user.role.role
      if (role.accessLevel === roleLevels.viewAll)
        territorio = "";
      else if (role.accessLevel >= roleLevels.viewArea)
        territorio = this.props.user.role.areaName;
      else if (role.accessLevel >= roleLevels.viewManyDistrict)
        territorio = this.props.user.role.districtName;
      else if (role.accessLevel >= roleLevels.viewDistrict)
        territorio = this.props.user.role.districtName;
      else
        territorio = this.props.user.role.territory.territoryName;
    }
    //console.log(this.props);
    if (!this.props.clientesVendaExterna.isLoading && this.props.clientesVendaExterna && !this.props.user.isLoadingTerritorios && this.props.user.territorios.length > 0 && !this.props.user.isLoadingRole && this.props.user.role != null && !this.props.user.isLoadingRoleLevels && this.props.user.roleLevels != null) {

      var territoriosToFilter = this.props.user.territorios
      areas = areas.concat(this.props.user.role.areas);
      areas = areas.filter(x => x !== '');
      var distritosToFilter = this.props.user.role.distritos;

      if (!this.state.isSetStorage) {
        this.setByStorage(this.props.user.role.areas, this.props.user.role.distritos, this.props.user.territorios)
      }

      rows = this.props.clientesVendaExterna.clients;
      if (this.state.area !== "" && this.state.area != null && this.state.area !== undefined && role.accessLevel === roleLevels.viewAll) {
        if (this.state.area !== "TODOS") {
          distritosToFilter = distritosToFilter.filter(x => x.areaName === this.state.area);
          territoriosToFilter = territoriosToFilter.filter(x => x.areaName === this.state.area)
          rows = rows.filter(x => territoriosToFilter.find(t => x.territory.territoryName === t.territoryName))
        }
      }

      distritos = distritos.concat(distritosToFilter);
      distritos = distritos.filter(x => x.districtName !== '');
      if (this.state.distrito !== "" && this.state.distrito != null && this.state.distrito !== undefined && (role?.accessLevel >= roleLevels?.viewArea || (role?.accessLevel >= roleLevels?.viewManyDistrict && distritos.length > 2))) {
        if (this.state.distrito.districtName !== "TODOS") {
          territoriosToFilter = territoriosToFilter.filter(x => x.districtName === this.state.distrito.districtName)
          rows = rows.filter(x => territoriosToFilter.find(t => x.territory.territoryName === t.territoryName))
        }
      }

      if (this.state.territorio !== "" && this.state.territorio != null && this.state.territorio !== undefined && (role?.accessLevel >= roleLevels?.viewDistrict)) {
        if (this.state.territorio.territoryName !== "TODOS")
          rows = rows.filter(x => x.territory.territoryName === this.state.territorio.territoryName)
      }
      territorios = territorios.concat(territoriosToFilter)
    }

    var isSelected = this.state.territorio !== "" && this.state.territorio !== undefined && this.state.territorio != null && this.state.territorio.territoryName !== "TODOS"
    var isSubFilterSelected = (this.state.area !== "" && this.state.area != null && this.state.area !== undefined && this.state.area !== "TODOS") || (this.state.distrito !== "" && this.state.distrito != null && this.state.distrito !== undefined && this.state.distrito.districtName !== "TODOS")
    var territoriosToShow = isSelected ? [this.state.territorio] : isSubFilterSelected ? territorios : this.props.user.territorios;

    return (
      <div className="card">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.clientesVendaExterna.isLoading || this.props.user.isLoadingTerritorios || this.props.user.isLoadingRole || this.props.user.isLoadingRoleLevels}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={"/"}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/"}>
                Home
              </Link>
              <Typography color="text.primary">C360 Light</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <h3 style={{ color: "var(--cort-blue)" }}>Olá, {oidc.user.profile.name}.</h3>
          <h1 style={{ color: "var(--cort-blue)", fontWeight: "bold" }} className="mb-0">Bem-vindo ao Customer 360.</h1>
          {/* <Alert severity="info">{"Manutenção Programada! No dia 09/03/23 entre 16h até as 19h a plataforma receberá uma manutenção programada e poderá ocorrer indisponibilidades durante o período informado."}</Alert> */}
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }} sx={{ marginTop: "0 !important" }}>
            {(!(this.props.user.isLoadingTerritorios || this.props.user.isLoadingRole || this.props.user.isLoadingRoleLevels) && role.accessLevel > roleLevels?.viewTerritory) &&
              <Grid item xs={2} sm={8} md={12} >
                <Grid container spacing={2}>
                  <Grid item xs={12} className="rounded-3"
                    sx={{
                      marginLeft: "16px",
                      marginTop: "16px",
                      backgroundColor: "#e6e8ea",
                      fontWeight: "500",
                      padding: "8px 16px !important",
                    }}
                  >
                    <Stack
                      justifyContent="flex-start"
                      alignItems="center"
                      direction={{ xs: 'column', sm: 'column', md: 'row' }}
                      spacing={{ xs: 1, sm: 2, md: 2 }}
                    >
                      <h6 className="mb-0">Selecione um território para filtrar a visualização dos dados:</h6>
                      <ButtonSelectTerrritory variant="outlined" color={"azul"} startIcon={<EditIcon />}
                        selectedArea={this.state.area ? this.state.area : areas[0]}
                        areas={areas}
                        onChangeArea={this.onChangeArea}
                        selectedDistrito={this.state.distrito ? this.state.distrito : distritos[0]}
                        distritos={distritos}
                        onChangeDistrito={this.onChangeDistrito}
                        selectedTerritorio={this.state.territorio ? this.state.territorio : territorios[0]}
                        territorios={territorios}
                        onChangeTerritorio={this.onChangeTerritorio}
                        role={role}
                        roleLevels={roleLevels}
                      >Editar Filtros</ButtonSelectTerrritory>
                      {(role.accessLevel === roleLevels.viewAll) &&
                        <p className="mb-0">Area Comercial: {this.state.area ? this.state.area : areas[0]}</p>
                      }
                      {(role?.accessLevel >= roleLevels?.viewArea || (role?.accessLevel >= roleLevels?.viewManyDistrict && distritos.length > 2)) &&
                        <p className="mb-0">Distrito: {(this.state.distrito ? this.state.distrito : distritos[0]).districtName}</p>
                      }
                      {(role?.accessLevel >= roleLevels?.viewDistrict) &&
                        <p className="mb-0">Território: {(this.state.territorio ? this.state.territorio : territorios[0]).territoryName}</p>
                      }
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>

            }

            <Grid item xs={2} sm={8} md={9}>
              <p>
                Esta é sua carteira de clientes.
                <br />
                Selecione um cliente e clique em uma das ações disponíveis.
                <br />
              </p>
            </Grid>
          </Grid>
          {!this.props.user.isLoadingTerritorios &&
            <CustomPaginationActionsTable rows={rows} oidc={oidc} territorios={territoriosToShow} role={role} />
          }
        </div>
      </div>

    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...clientTerritoryActions,
        ...userActions,
        ...clientActions
      }, dispatch),
    };
  }
)(SimulatorHomePage);
