const requestCampaigns = "REQUEST_CAMPAIGNS_VE";
const receiveCampaigns = "RECEIVE_CAMPAIGNS_VE";

const clearCampaignStore = "CLEAR_CAMPAIGN_STORE";

const initialState = { campaigns: [], isLoading: false };
const apiBase = "/api/SimuladorVendaExterna/CampanhasVendaExterna";

export const campaignVeActions = {
  requestCampanhas: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestCampaigns });

    const url = `${apiBase}/list`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const campaigns = await response.json();

    dispatch({ type: receiveCampaigns, campaigns });
  },
  limparCampanhas: () => async (dispatch) => {
    dispatch({ type: clearCampaignStore });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case requestCampaigns:
      return {
        ...state,
        isLoading: true,
      };
    case receiveCampaigns:
      return {
        ...state,
        isLoading: false,
        campaigns: action.campaigns
      };
    case clearCampaignStore:
      return {
        ...state,
        campaign: undefined,
        campaigns: [],
      };
    default:
      return state;
  }
};
