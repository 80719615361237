import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Link } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as SimuladorDDSSvg } from "../../../assets/svg/SimuladorDDS.svg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { userActions } from "../../../actions/user.actions";
import { FormControl, FormControlLabel, FormGroup, InputBase, Popover, Stack, Switch, Toolbar, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from '@mui/icons-material/FilterList';
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { nivelSemaforoActions } from "../../../actions/nivelSemaforo.actions";
import CircleIcon from '@mui/icons-material/Circle';
import { simulator2Actions } from "../../../actions/simulator2.actions";
import { GetSafeNumber } from "../../../helpers";
import { workflowActions } from "../../../actions/workflow.actions";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
          padding: "6px 6px",
          "&:first-of-type": {
            padding: "6px 6px 6px 16px",
          },
          "&:last-child": {
            padding: "6px 16px 6px 6px",
          },
          minWidth: "77px"
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    }
  },
});

const zeroPad = (num, places) => String(num).padStart(places, '0')

function EnhancedTableToolbar({ msgSave = "", status = [], statusSelected = [], handleChangeSelect = (event) => { }, handleSearchText = (event) => { } }) {
  return (
    <Toolbar sx={{ padding: "0 !important" }}>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: 850,
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Pesquise por cliente (Visão única) ou por Território"
          onChange={handleSearchText}
          inputProps={{ "aria-label": "Pesquise por cliente (Visão única) ou por Território" }}
        />
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
      </Paper>
      {/* <Typography sx={{ flex: "1 1 40%", textAlign: "end" }} component="div">
        {msgSave}
      </Typography>

      <Tooltip title="Atualizar dados">
        <IconButton>
          <RefreshIcon />
        </IconButton>
      </Tooltip> */}
    </Toolbar>
  );
}

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />

      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />

      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

function defaultLabelDisplayedRows({ from, to, count }) { return `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`; }

function CustomPaginationActionsTable({ rows = [], status = [], statusSelected = [], roles = [], rolesFilter = [], handleChangeSelect = (event) => { }, handleSelectSR = (event) => { }, clients }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeOpt = (event) => {
    handleChangeSelect(event);
    setPage(0);
  }

  const handleChangeSR = (event) => {
    handleSelectSR(event);
    setPage(0);
  }

  return (
    <TableContainer component={Paper}>
      <ThemeProvider theme={(mainTheme) => createTheme({ ...mainTheme, ...theme, palette: { ...mainTheme.palette } })}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="custom pagination table">
          <TableHead sx={{
            backgroundColor: "var(--cort-blue)",
            "th": {
              color: "#FFF",
              fontWeight: "bold"
            },
          }}>
            <TableRow>
              <TableCell size="medium" align="center">Simulação</TableCell>
              <TableCell size="medium">Data de Envio</TableCell>
              <TableCell size="medium">Território</TableCell>
              <TableCell size="medium">Cliente</TableCell>
              {/* <TableCell size="medium">Campanha</TableCell>
              <TableCell size="medium">Produtos</TableCell> */}
              <TableCell size="medium" align="right">Valor Final</TableCell>
              <TableCell size="medium" align="center">Visualizar Cenário</TableCell>
              <TableCell size="medium" align="center">
                <PopupState variant="popover" popupId="demo-popup-popover">
                  {(popupState) => (
                    <>
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 0, sm: 0.5, md: 1 }}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <div>Status</div>
                        <Tooltip title="Filtro" >
                          <IconButton {...bindTrigger(popupState)} color="fullWhite" size="small" >
                            <FilterListIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <FormControl component="fieldset" className="p-1">
                          <FormGroup aria-label="position">
                            {status.map((st) => (
                              <FormControlLabel
                                key={st.codigo}
                                value="top"
                                control={
                                  <Switch
                                    checked={statusSelected.indexOf(st.texto) > -1}
                                    size="small"
                                    name={st.texto}
                                    onChange={handleChangeOpt}
                                  />
                                }
                                sx={{ marginLeft: 0 }}
                                label={st.texto}
                              />
                            ))}
                          </FormGroup>
                        </FormControl>
                      </Popover>
                    </>
                  )}
                </PopupState>
              </TableCell>
              <TableCell size="medium" align="center">Níveis de Aprovação</TableCell>
              <TableCell size="medium" align="center">
                <PopupState variant="popover" popupId="demo-popup-popover">
                  {(popupState) => (
                    <>
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 0, sm: 0.5, md: 1 }}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <div>Pendente Aprovação</div>
                        <Tooltip title="Filtro" >
                          <IconButton {...bindTrigger(popupState)} color="fullWhite" size="small" >
                            <FilterListIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <FormControl component="fieldset" className="p-1">
                          <FormGroup aria-label="position">
                            {roles.map((role) => (
                              <FormControlLabel
                                key={role}
                                value="top"
                                control={
                                  <Switch
                                    checked={rolesFilter.indexOf(role) > -1}
                                    size="small"
                                    name={role}
                                    onChange={handleChangeSR}
                                  />
                                }
                                sx={{ marginLeft: 0 }}
                                label={role}
                              />
                            ))}
                          </FormGroup>
                        </FormControl>
                      </Popover>
                    </>
                  )}
                </PopupState>
              </TableCell>
              <TableCell size="medium" align="center">Score</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row, index) => (
              <TableRow
                key={row.cenarioId}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  'th': {
                    maxWidth: '400px'
                  }
                }}
              >
                <TableCell component="th" scope="row" align="center">
                  {zeroPad(row.cenarioId, 4)}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.dataCriada}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.territorio}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.clienteName}
                </TableCell>
                <TableCell align="right">
                  {
                    Number(parseFloat(row.valorFinal).toFixed(2))
                      .toLocaleString("pt-br", {
                        style: "currency",
                        currency: row.moeda,
                      })
                  }
                </TableCell>
                <TableCell align="center">
                  <Link
                    className="mx-1"
                    to={`/CenarioDetails2?ClienteId=${row.clienteId}&CenarioId=${row.cenarioId}`}
                  >
                    <SimuladorDDSSvg height={30} />
                  </Link>
                </TableCell>
                <TableCell align="center">
                  {row.situacaoCenario}
                </TableCell>
                <TableCell align="center">
                  {
                    row.approversCount
                  }
                </TableCell>
                <TableCell align="center">
                  {row.situacaoCenario == "Reprovado" ? "Finalizado" : (row.situacaoCenario == "Aprovado" ? "Finalizado" : row.actualApprover)}
                </TableCell>
                <TableCell align="center">
                  <CircleIcon sx={{ color: `${row.scoreBp === 0 ? "#C00000" : row.scoreBp === 1 ? "#FFC000" : row.scoreBp === 2 ? "#00B050" : "var(--bs-border-color)"}` }} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ThemeProvider>
      <TablePagination
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={defaultLabelDisplayedRows}
        component="div"
        rowsPerPageOptions={[5, 10, 25]}
        colSpan={3}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            'aria-label': 'Linhas por página',
          },
          native: false,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </TableContainer>
  );
}

class WorkflowPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clientId: "",
      showAll: true,
      statusSelected: ["Aguardando Aprovação"],
      situacao: [
        // { texto: "Rascunho", codigo: 0 },
        // { texto: "Salvo", codigo: 1 },
        // { texto: "Excluído", codigo: 2 },
        { texto: "Aguardando Aprovação", codigo: 3 },
        { texto: "Aprovado", codigo: 4 },
        { texto: "Reprovado", codigo: 5 },
      ],
      roleFilter: [],
      searchText: ""
    };
  }

  componentDidMount() {
    this.props.actions.requestClients();
    this.props.actions.requestAllCenarios();
    this.props.actions.requestRole();
    this.props.actions.requestRoleLevels();
  }

  handleChangeSelect = (event) => {
    const { name, checked } = event.target;
    let { statusSelected } = this.state;
    if (checked && statusSelected.indexOf(name) === -1) {
      statusSelected.push(name);
    }

    if (!checked && statusSelected.indexOf(name) > -1) {
      statusSelected = statusSelected.filter((b) => b !== name);
    }

    this.setState({
      statusSelected: statusSelected,
    });
  };

  handleSelectSR = (event) => {
    const { name, checked } = event.target;
    let { roleFilter } = this.state;
    if (checked && roleFilter.indexOf(name) === -1) {
      roleFilter.push(name);
    }

    if (!checked && roleFilter.indexOf(name) > -1) {
      roleFilter = roleFilter.filter((b) => b !== name);
    }

    this.setState({
      roleFilter: roleFilter,
    });
  };

  handleSearchText = (event) => {
    const { value } = event.target;
    this.setState({ searchText: value });
  };

  render() {
    const { oidc } = this.props;
    var rows = [];
    var role = "";
    var situacao = this.state.situacao;
    var cliente = "";

    if (!this.props.user.isLoadingRole && this.props.user.role != null && !this.props.user.isLoadingRoleLevels && this.props.user.roleLevels != null) {
      role = this.props.user.role.role
      if (role.isAdmin || role.workflowViewSave)
        situacao = [{ texto: "Salvo", codigo: 1 }, ...situacao];
    }

    const statusList = [];
    situacao.filter((s) => this.state.statusSelected.indexOf(s.texto) > -1).map((st) => statusList.push(st.codigo));

    rows = this.props.workflow.listCenarios;
    var rowsFilter = rows.filter((k) => statusList.indexOf(k.situacaoCenario) > -1);

    rows = rowsFilter.map(c => {
      const situacaoRow = situacao.find(x => x.codigo === c.situacaoCenario)?.texto;
      if (!this.props.simulator2.clientes.isLoading && this.props.simulator2.clientes) {
        var cliente = this.props.simulator2.clientes.clients.find(x => x.grupoId === c.clienteId && x.territorio === c.territorio);
      }

      var dataCriada = (new Date(c.dataCriada)).toLocaleDateString("pt-BR");

      var lastApprovers = c?.cenarioWorkflow?.[0]?.workflowCondition?.approvers;
      var approversCount = lastApprovers?.length;
      var actualApprover = lastApprovers?.filter(x => x.order == c?.cenarioWorkflow?.[0]?.workflowOrder + 1)?.map(x => x.role)?.join(",");

      return {
        "cenarioId": c.id,
        "dataCriada": dataCriada,
        "situacaoCenario": situacaoRow,
        "statusCenario": c.statusCenario,
        "distrito": c.distrito,
        "clienteId": cliente?.id ?? 0,
        "clienteName": cliente ? cliente.nomeVisaoUnica : "",
        "territorio": c.territorio,
        "valorFinal": GetSafeNumber(c.totalSimulacao),
        "moeda": c.moeda == null || c.moeda === undefined ? "USD" : c.moeda,
        "score": c.scoreNegociacaoCpa,
        "scoreBp": c.corGm,
        "approversCount": approversCount,
        "actualApprover": actualApprover,
      }
    });

    const roles = [...new Set(rows.map(x => x.actualApprover))];
    rows = rows.filter((row) => this.state.roleFilter.length === 0 ? true : this.state.roleFilter?.includes(row.actualApprover));

    if (this.state.searchText !== "") {
      rows = rows.filter((k) =>
        k?.territorio?.toLowerCase().includes(this.state.searchText.toLowerCase()) ||
        k?.cienteName?.toLowerCase().includes(this.state.searchText.toLowerCase())
      );
    }

    return (
      <div className="card">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.simulator2.clientes.isLoading || this.props.workflow.listCenarios.isLoading || this.props.user.isLoadingRole || this.props.user.isLoadingRoleLevels}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={"/"}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/"}>
                Home
              </Link>
              <Typography color="text.primary">Workflow Aprovação 2.0</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }} sx={{ marginTop: "0 !important" }}>
            <Grid item xs={2} sm={8} md={9.3}>
              <Box>
                <h3 style={{ color: "var(--cort-blue)" }}>Olá, {oidc.user.profile.name}.</h3>
                <h1 style={{ color: "var(--cort-blue)", fontWeight: "bold" }} className="mb-0">Bem-vindo ao Customer 360.</h1>
              </Box>
            </Grid>
            {this.props.user.role?.role?.cpaView && <Grid item xs={2} sm={8} md={2.7} display={"flex"} justifyContent={"flex-end"}>
              <Box sx={{ alignItems: 'flex-end' }}>
                <Button
                  component={Link}
                  to={`/Cpa`}
                  variant="contained"
                  size="medium"
                  className="py-3"
                  color='azul'
                  sx={{ fontSize: "12pt", color: "#fff !important", textAlign: "center" }}
                >
                  Módulo CPA
                </Button>
              </Box>
            </Grid>}
          </Grid>

          <EnhancedTableToolbar
            handleSearchText={this.handleSearchText}
          />

          <CustomPaginationActionsTable
            rows={rows}
            statusSelected={this.state.statusSelected}
            rolesFilter={this.state.roleFilter}
            status={situacao}
            roles={roles}
            handleSelectSR={this.handleSelectSR}
            handleChangeSelect={this.handleChangeSelect}
            clients={cliente}
          />
        </div>
      </div>
    );
  }
}


export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...workflowActions,
        ...simulator2Actions,
        ...userActions,
        ...nivelSemaforoActions,
      }, dispatch),
    };
  }
)(WorkflowPage);
