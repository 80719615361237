/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { userActions } from "../../../actions/user.actions";
import { clientTerritoryActions } from "../../../actions/clientTerritory.actions";
import { precoVeActions } from "../../../actions/precove.actions";
import { simuladorVeEncargosActions } from "../../../actions/simuladorVeEncargos.action";
import { produtoveActions } from "../../../actions/produtove.action";
import { Checkbox, FormControlLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, createTheme } from "@mui/material";
import { simuladorVeActions } from "../../../actions/simuladorVendaExterna.actions";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
          minWidth: "90px",
          padding: "6px 6px",
          "&:first-of-type": {
            padding: "6px 6px 6px 16px",
          },
          "&:last-child": {
            padding: "6px 16px 6px 6px",
            minWidth: "fit-content"
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    }
  }
});

class SimulatorEditPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cliente: null,
      account: null,
      territorio: null,
      cli: 0
    };
  }

  componentWillMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    this.setState({ cli: params.GroupId });

    this.props.actions.requestClientTerritories();
    this.props.actions.simulacaoCliente(params.GroupId);
    this.props.actions.loadCenario(params.CenarioId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clientesVendaExterna.isLoading && !this.props.clientesVendaExterna.isLoading) {
      const { cli } = this.state;
      const selectedClient = this.props.clientesVendaExterna.clients.find(client => client.id == cli);

      this.setState({
        cliente: this.props.clientesVendaExterna.client,
        account: selectedClient?.account,
        territorio: selectedClient?.territory
      });
    }
  }

  componentWillUnmount(){
    this.props.actions.clearSimuladorVeData();
  }

  render() {
    const { account, territorio } = this.state;
    const rows = this.props.simuladorVendaExterna.rows ?? [];
    console.log(this.props.simuladorVendaExterna.cenario, this.props.simuladorVendaExterna.rows);

    var total = 0;
    for (var i = 0; i < rows.length; i++) {
      total = total + rows[i].valorFinalNegociado;
    }

    return (
      <div className="card">
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={`/SimuladorVe/MinhasSimulacoesCliente?GroupId=${this.state.cli}`}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/"}>
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={"/simuladorVe"}
              >
                C360 Light
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={`/simuladorVe/MinhasSimulacoesCliente?GroupId=${this.state.cli}`}
              >
                Meus Pedidos
              </Link>
              <Typography color="text.primary">Novo Pedido</Typography>
            </Breadcrumbs>
          </Stack>
        </div>

        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={8} md={6}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1 style={{ color: "var(--cort-blue)" }}>{account?.nome ?? ""}</h1>
              </div>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Account:</span> {account?.accountId}</h6>
              {/* <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Território:</span> {territorio?.territoryName}</h6> */}
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Distrito:</span> {territorio?.districtName}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Endereço:</span> {account?.endereco}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Cidade:</span> {account?.municipio} - {account?.uf}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Inscrição Estadual:</span> {account?.ie}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>CPF/CNPJ:</span> {account?.cpfCnpj}</h6>
            </Grid>

            <Grid item xs={2} sm={8} md={6}>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                {total > 0 && (
                  <>
                    <Grid item xs={2} sm={8} md={7} sx={{ marginTop: { xs: 0, sm: 0, md: "14px" } }} className="align-self-center">
                      <h4>
                        <b>Valor total do Pedido: </b>
                      </h4>
                    </Grid>
                    <Grid item xs={2} sm={8} md={5} sx={{ marginTop: { xs: 0, sm: 0, md: "14px" } }} className="align-self-center">
                      <h4>
                        {
                          total.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: (this.state.moeda === "BRL" ? "BRL" : "USD"),
                          })
                        }
                      </h4>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid item xs={2} sm={8} md={6}>
              <label className="d-block">Campanha</label>
              <TextField
                disabled
                id="outlined-disabled"
                label={this.props.simuladorVendaExterna?.cenario?.campanha?.nome}
                sx={{ width: "100%" }} size="small"
              />
            </Grid>

            <Grid item xs={2} sm={8} md={3}>
              <label className="d-block">Moeda</label>
              <TextField
                disabled
                id="outlined-disabled"
                label={this.props.simuladorVendaExterna?.cenario?.moeda}
                sx={{ width: "100%" }} size="small"
              />
            </Grid>

            <Grid item xs={2} sm={8} md={3}>
              <label className="d-block">Prazo de Pagamento</label>
              <TextField
                disabled
                id="outlined-disabled"
                label={this.props.simuladorVendaExterna?.cenario?.condicaoComercial}
                sx={{ width: "100%" }} size="small"
              />
            </Grid>

            <Grid item xs={2} sm={8} md={12} >
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }} className="rounded-3">
                <Grid item xs={2} sm={8} md={10.5}>
                  <label>Observação</label>
                  <TextField
                    disabled
                    color={'azul'}
                    fullWidth
                    size="small"
                    id="observacao"
                    name="observacao"
                    variant="outlined"
                    value={this.props.simuladorVendaExterna?.cenario?.observacao}
                  />
                </Grid>

                <Grid item xs={2} sm={8} md={1.5}>
                  <div style={{ marginBottom: ".5rem", opacity: "0" }}>SPACING</div>
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                        checked={this.props.simuladorVendaExterna?.cenario?.barter ? true : false}
                      />
                    }
                    id="barter"
                    name="barter"
                    label="É Barter"
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={2} sm={8} md={12} >
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "0 !important", color: "white", backgroundColor: "var(--cort-blue)", fontWeight: "bold" }}>
                <Grid item xs={2} sm={1.5} md={2.5} sx={{ textAlign: { xs: "center", sm: "left" }, paddingTop: "1rem !important" }}>
                  <p>Cesta do Cliente</p>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={2} sm={8} md={12}>
              {rows.length > 0 &&
                <TableContainer component={Paper}>
                  <ThemeProvider theme={(mainTheme) => createTheme({ ...mainTheme, ...theme, palette: { ...mainTheme.palette } })}>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="custom pagination table"
                    >
                      <TableHead>
                        <TableRow
                          sx={{
                            th: {
                              fontWeight: "bold",
                            },
                          }}
                        >
                          <TableCell align="center">Ordem</TableCell>
                          <TableCell align="center">Produto</TableCell>
                          <TableCell align="center">Preço Sugerido</TableCell>
                          <TableCell align="center">Múltiplo</TableCell>
                          <TableCell align="center">Quantidade (Kg / L)</TableCell>
                          <TableCell align="center">Preço Negociado</TableCell>
                          <TableCell align="center">Data de Entrega</TableCell>
                          <TableCell align="center">Valor Final</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.sort((a, b) => a.ordem - b.ordem).map((row, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row" align="center">
                              {row.ordem}
                            </TableCell>

                            <TableCell component="th" scope="row" align="center">
                              {row.produto.nome}
                            </TableCell>

                            <TableCell align="center">
                              {row.precoSugerido.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: (this.state.moeda === "BRL" ? "BRL" : "USD"),
                              })}
                            </TableCell>

                            <TableCell align="center">
                              {row.produto.multiploVolume}
                            </TableCell>

                            <TableCell align="center">
                              {row.volume}
                            </TableCell>

                            <TableCell align="center">
                              {row.precoNegociado}
                            </TableCell>

                            <TableCell align="center">
                              {new Date(row.dataEntrega).toLocaleDateString("pt-BR")}
                            </TableCell>

                            <TableCell align="center">
                              {row.valorFinalNegociado.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: (this.state.moeda === "BRL" ? "BRL" : "USD"),
                              })}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </ThemeProvider>
                </TableContainer >
              }
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators(
        {

          ...clientTerritoryActions,
          ...precoVeActions,
          ...produtoveActions,
          ...simuladorVeEncargosActions,
          ...simuladorVeActions,
          ...userActions
        },
        dispatch
      ),
    };
  }
)(SimulatorEditPage);
