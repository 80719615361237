import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, FormHelperText, Stack, TextField } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import FormControl from '@mui/material/FormControl';
import { useFormik } from 'formik';
import AllowUseNavigate, { navigate } from '../../../../Navigate';
import { adminSimuladorVeActions } from '../../../../../actions/admin/simuladorVe.actions';


const FormGrupoMaterial = ({ grupoMaterial, send }) => {
  const formik = useFormik({
    initialValues: {
      nome: grupoMaterial?.nome || '',
    },
    validate: (values) => {
      const errors = {};
      if (!values.nome) {
        errors.nome = 'Nome é obrigatório';
      }
      return errors;
    },
    onSubmit: (values) => {
      send(values);
    },
  });

  useEffect(() => {
    if (grupoMaterial.nome) {
      formik.setFieldValue('nome', grupoMaterial.nome);
    }
  }, [grupoMaterial]);

  return (
    <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
      <Stack spacing={2}>
        <FormControl fullWidth error={formik.touched.nome && Boolean(formik.errors.nome)}>
          <TextField
            id="nome"
            label="Nome"
            variant="standard"
            type="text"
            value={formik.values.nome}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.nome && <FormHelperText>{formik.errors.nome}</FormHelperText>}
        </FormControl>
        <Button variant="contained" color="primary" fullWidth type="submit">
          Salvar
        </Button>
      </Stack>
    </form>
  );
};

class AdminSimuladorCreateFornecedorPage extends React.Component {
  constructor(props) {
    super(props);

    this.sendGrupoMaterial = this.sendGrupoMaterial.bind(this);
  }

  // componentWillUnmount() {
  //   this.props.actions.clearFornecedor();
  // }

  componentWillMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.GrupoMaterialId) {
      this.props.actions.requestGrupoMaterial(params.GrupoMaterialId)
    }

  }

  sendGrupoMaterial(grupoMaterial) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.GrupoMaterialId)
      this.props.actions.updateGrupoMaterial(params.GrupoMaterialId, grupoMaterial, navigate);
    else
      this.props.actions.saveGrupoMaterial(grupoMaterial, navigate);
  }


  render() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    var isLoading = this.props.adminSimuladorVe.isLoading;

    const grupoMaterial = this.props.adminSimuladorVe.grupoMaterial ? this.props.adminSimuladorVe.grupoMaterial : [];
  
    return (
      <div className="card" style={{ borderRadius: 0 }}>
        <AllowUseNavigate />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={"/Admin-GrupoMaterial"}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/Admin-GrupoMaterial"}>
                Grupo Material
              </Link>
              <Typography color="text.primary">Novo Grupo Material</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <FormGrupoMaterial grupoMaterial={grupoMaterial} send={this.sendGrupoMaterial} />
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...adminSimuladorVeActions,
      }, dispatch),
    };
  }
)(AdminSimuladorCreateFornecedorPage);
