import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow'
import { clientTerritoryActions } from "../../../actions/clientTerritory.actions";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from "@mui/material/Grid";
import { userActions } from "../../../actions/user.actions";
import { simuladorVeActions } from "../../../actions/simuladorVendaExterna.actions";
import { Dialog, DialogActions, DialogTitle, Stack } from "@mui/material";
import { IconButton, Tooltip } from "@mui/material";
import { ReactComponent as SimuladorDDSSvg } from "../../../assets/svg/SimuladorDDS-Cut.svg";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import { produtoveActions } from "../../../actions/produtove.action";

class SimulatorClientePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clientId: "",
      open: false,
      statusSelected: ["Rascunho", "Salvo", "Aguardando Aprovação", "Aprovado", "Reprovado", "Cancelado"],
      situacao: [
        { texto: "Rascunho", codigo: 0 },
        { texto: "Salvo", codigo: 1 },
        { texto: "Aguardando Aprovação", codigo: 3 },
        { texto: "Aprovado", codigo: 4 },
        { texto: "Reprovado", codigo: 5 },
        { texto: "Cancelado", codigo: 6 },
      ],
      cliente: undefined,
      account: undefined,
      territorio: undefined,
      page: 0,
      rowsPerPage: 10,
      deleteId: null,
    };

  }

  componentWillMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    this.setState({ clientId: params.GroupId });

    this.props.actions.requestClientTerritories();
    this.props.actions.requestClient(params.GroupId);
    this.props.actions.requestProdutoPorTerritorio(params.GroupId);
    this.props.actions.requestRole();
    this.props.actions.simulacaoCliente(params.GroupId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clientesVendaExterna.clients !== this.props.clientesVendaExterna.clients) {
      const { clientId } = this.state;
      const selectedClient = this.props.clientesVendaExterna.clients.find(client => client.id == clientId);

      this.setState({
        cliente: this.props.clientesVendaExterna.client,
        account: selectedClient?.account,
        territorio: selectedClient?.territory
      });
    }
  }

  removeCenario(cenarioId) {
    this.props.actions.deleteCenario(cenarioId, this.props.actions.requestCenarios, this.state.clientId);
  }

  cancelCenario(cenarioId, motivo) {
    this.props.actions.cancelCenario(cenarioId, motivo, this.props.actions.requestCenarios, this.state.clientId);
  }

  handleClickOpen = (cenarioId) => {
    this.setState({ open: true, deleteId: cenarioId });
  };

  handleClose = () => {
    this.setState({ open: false, deleteId: null });
  };

  handleDeleteRow = () => {
    this.props.actions.deleteCenario(this.state.deleteId, this.props.actions.requestCenarios, this.state.clientId);
    this.handleClose();
  }

  handleChangePage(event, newPage) {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  handleChangeSelect = (event) => {
    const { name, checked } = event.target;
    let { statusSelected } = this.state;
    if (checked && statusSelected.indexOf(name) === -1) {
      statusSelected.push(name);
    }

    if (!checked && statusSelected.indexOf(name) > -1) {
      statusSelected = statusSelected.filter((b) => b !== name);
    }

    this.setState({
      statusSelected: statusSelected,
    });
  };

  handleRemoveRow = () => {
    this.props.actions.removeAllRows();
  }

  render() {
    const { clientesVendaExterna, user, simuladorVendaExterna } = this.props;
    const { isLoading: clientesLoading } = clientesVendaExterna;
    const { isLoading: userLoading } = user;
    const { isLoading: simuladorLoading, itens: simulacoes } = simuladorVendaExterna;
    const { account, territorio } = this.state;

    const rows = simulacoes ? simulacoes : [];
    return (
      <div className="card">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={clientesLoading || simuladorLoading || userLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={"/SimuladorVe"}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/"}>
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={"/SimuladorVe"}
              >
                C360 Light
              </Link>
              <Typography color="text.primary">Meus Pedidos</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={8} md={6}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1 style={{ color: "var(--cort-blue)" }}>{account?.nome ?? ""}</h1>
              </div>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Account:</span> {account?.accountId}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Território:</span> {territorio?.territoryName}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Distrito:</span> {territorio?.districtName}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Endereço:</span> {account?.endereco}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Cidade:</span> {account?.municipio} - {account?.uf}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Inscrição Estadual:</span> {account?.ie}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>CPF/CNPJ:</span> {account?.cpfCnpj}</h6>
            </Grid>
            <Grid item xs={2} sm={8} md={6}>
              <Stack
                direction={{ xs: "column", sm: "row", md: "row" }}
                spacing={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button component={Link}
                  to={`/SimuladorVe/Simulador-Cliente?GroupId=${this.state.clientId}`}
                  variant="contained"
                  size="large"
                  className="py-3"
                  color="azul"
                  sx={{
                    fontSize: "14pt",
                    width: { xs: "100%", sm: "100%", md: "fit-content" },
                    color: "#fff !important",
                    textAlign: "center"
                  }}>
                  Novo Pedido
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={2} sm={8} md={12}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="custom pagination table">
                  <TableHead sx={{
                    backgroundColor: "var(--cort-blue)",
                    "th": {
                      color: "#FFF",
                      fontWeight: "bold"
                    },
                  }}>
                    <TableRow>
                      <TableCell size="medium" align="center">Pedido</TableCell>
                      <TableCell size="medium" align="center">Data do pedido</TableCell>
                      <TableCell size="medium" align="center">Produtos</TableCell>
                      <TableCell size="medium" align="right">Valor Final</TableCell>
                      <TableCell size="medium" align="right">Status</TableCell>
                      <TableCell size="medium" align="center">Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => {
                      return (
                        <TableRow >
                          <TableCell align="center">
                            {"#" + String(row.id).padStart(6, '0')}
                          </TableCell>
                          <TableCell align="center">
                            {new Date(row.dataCriacao).toLocaleDateString()}
                          </TableCell>
                          <TableCell align="center">
                            {row.simuladorVendaExternaItens?.map((item) => item.produto?.nome || "").join(",  ")}
                          </TableCell>
                          <TableCell align="right">
                            {
                              row.total.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: (row.moeda === "BRL" ? "BRL" : "USD"),
                              })
                            }
                          </TableCell>
                          <TableCell align="right">
                            {row.status}
                          </TableCell>
                          <TableCell align="center">
                            <Stack
                              direction={{ xs: 'column', sm: 'row' }}
                              spacing={{ xs: 1, sm: 1.5, md: 2 }}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Link
                                to={`/SimuladorVe/Cenario?CenarioId=${row.id}&GroupId=${this.state.clientId}`}
                              >
                                <Tooltip title="Visualizar Pedido">
                                  <IconButton>
                                    <SimuladorDDSSvg style={{ height: "24px", widht: "24px" }} />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                              <Tooltip title="Editar Pedido">
                                <span>
                                  <IconButton
                                    component={Link}
                                    to={`/SimuladorVe/Simulador-Cliente-Edit?GroupId=${this.state.clientId}&CenarioId=${row.id}`}
                                    disabled={row.status === "Finalizado"}
                                  >
                                    <EditIcon sx={{ color: row.status === "Finalizado" ? "grey" : "#000" }} />
                                  </IconButton>
                                </span>
                              </Tooltip>

                              <Tooltip title="Excluir">
                                <IconButton
                                  disabled={row.status === "Finalizado"}
                                  onClick={() => this.handleClickOpen(row.id)}>
                                  <DeleteIcon sx={{ color: row.status === "Finalizado" ? "grey" : "#000" }} />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  labelRowsPerPage="Linhas por página:"
                  component="div"
                  count={rows.length}
                  page={this.state.page}
                  onPageChange={this.handleChangePage}
                  rowsPerPage={this.state.rowsPerPage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage} />
              </TableContainer >
            </Grid>
          </Grid>
        </div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Tem certeza que deseja excluir este pedido?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancelar</Button>
            <Button onClick={this.handleDeleteRow} autoFocus>Excluir</Button>
          </DialogActions>
        </Dialog>
      </div >
    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...clientTerritoryActions,
        ...simuladorVeActions,
        ...userActions,
        ...produtoveActions,
      }, dispatch),
    };
  }
)(SimulatorClientePage);

