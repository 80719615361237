import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import AllowUseNavigate, { navigate } from '../../../../Navigate.jsx';
import { adminCampaignVeActions } from "../../../../../actions/admin/campaignVe.actions.js";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, Checkbox, FormControlLabel, Grid, Stack, TextField } from "@mui/material";
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { isNullOrEmpty } from "../../../../../helpers/validation.js";

function FormEdit({ data = { nome: "", prazoEntrega: "", ativo: true }, send = (form) => { }, ...props }) {
  const formik = useFormik({
    initialValues: data,
    validate: (values) => {
      const errors = {};
      if (!values.nome) {
        errors.nome = 'O nome da campanha é obrigatório';
      }

      return errors;
    },
    onSubmit: (values) => {
      send(values);
    },
  });

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <Grid container
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 2, sm: 8, md: 12 }}
        sx={{ width: "100% !important" }}
        direction="row"
        xl="auto"
        justifyContent="center"
        alignItems="flex-start"
        className="mt-4 mb-4">
        <Grid item xs={2} sm={8} md={9}>
          <TextField
            color={'azul'}
            fullWidth
            id={"nome"}
            name={"nome"}
            label={"Nome"}
            variant="standard"
            value={formik.values["nome"]}
            onChange={formik.handleChange}
            error={formik.touched["nome"] && Boolean(formik.errors["nome"])}
            helperText={formik.touched["nome"] && formik.errors["nome"]}
          />
        </Grid>
        <Grid item xs={2} sm={8} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={formik.values["ativo"]}
                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                value={formik.values["ativo"]}
                onChange={(event) => formik.setFieldValue("ativo", event.target.checked)}
              />
            }
            id={"ativo"}
            name={"ativo"}
            label={"Ativo"}
            variant="standard"
            error={formik.touched["ativo"] && Boolean(formik.errors["v"])}
            helperText={formik.touched["ativo"] && formik.errors["ativo"]}
          />
        </Grid>
      </Grid>
      <Button color="azul" variant="contained" fullWidth type="submit">
        Salvar
      </Button>
    </Box >
  );
}

class AdminVeCampaignForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0
    };

    this.sendForm = this.sendForm.bind(this);
  }

  componentWillMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const id = params.Id;

    if (id !== undefined && id !== '') {
      this.props.actions.get(id);
      this.setState({ id: id });
    }
  }

  componentWillUnmount() {
    this.props.actions.clear();
  }

  sendForm(form) {
    if (this.state.id !== undefined && this.state.id !== '')
      this.props.actions.update(this.state.id, form, navigate);
  }

  render() {
    const id = this.state.id;
    const isLoading = this.props.adminCampaignVe.isLoading;
    const isDataLoad = (isNullOrEmpty(id) || id === 0 || !isNullOrEmpty(this.props.adminCampaignVe?.campaign));

    return (
      <div className="card" style={{ borderRadius: 0 }}>
        <AllowUseNavigate />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <ArrowBackIcon color="transparent" />
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/Admin-CampaignVe"}>
                Campanhas
              </Link>
              <Typography color="text.primary">{(id !== undefined && id !== '') ? "Novo" : "Editar"} Campanha</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          {!isLoading && isDataLoad &&
            <FormEdit
              data={this.props.adminCampaignVe.campaign ?? undefined}
              send={this.sendForm}
            />
          }
        </div>
      </div>

    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...adminCampaignVeActions,
      }, dispatch),
    };
  }
)(AdminVeCampaignForm);