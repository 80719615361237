import React, { Component } from "react";
import { CallbackComponent } from "redux-oidc";
import userManager from "../../../util/userManager";
import { classUseRedirect } from "../../../util/classUseRedirect";

class CallbackPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      callbackCounter: 0
    }
  }

  handleSuccess = () => {
    this.props.redirect("/");
  };

  handleError = (error) => {
    if (this.state.callbackCounter > 0) {
      sessionStorage.clear();
      localStorage.clear();
      this.props.redirect("/logout");
    }
    else {
      this.setState({ callbackCounter: 1 });
    }
  };

  render() {
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={this.handleSuccess}
        errorCallback={this.handleError}
      >
        <React.Fragment>
        </React.Fragment>
      </CallbackComponent>
    )
  }
};

export default classUseRedirect(CallbackPage);