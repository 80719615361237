const requestEncargosVe = "REQUEST_ALL_ENCARGOS";
const receiveEncargosVe = "RECEIVE_ALL_ENCARGOS";
const requestEncargoVe = "REQUEST_ENCARGO";
const receiveEncargoVe = "RECEIVE_ENCARGO";

const initialState = { encargos: [], condicaoComercial: [], isLoading: false };

export const simuladorVeEncargosActions = {
  requestEncargos: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestEncargosVe });

    const url = `/api/simuladorVendaExterna/encargosVendaExterna`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const encargos = await response.json();
    dispatch({ type: receiveEncargosVe, encargos });
  },
  requestCondicaoComercial: (moeda, campanha) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestEncargoVe });

    const url = `/api/simuladorVendaExterna/encargosVendaExterna/condicaoComercial/${moeda}/${campanha}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const condicaoComercial = await response.json();
    dispatch({ type: receiveEncargoVe, condicaoComercial });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestEncargosVe) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === receiveEncargosVe) {
    return {
      ...state,
      encargos: action.encargos,
      isLoading: false,
    };
  }
  if (action.type === requestEncargoVe) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === receiveEncargoVe) {
    return {
      ...state,
      condicaoComercial: action.condicaoComercial,
      isLoading: false,
    };
  }

  return state;
};
