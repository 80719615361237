import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, FormHelperText, Stack, TextField } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import FormControl from '@mui/material/FormControl';
import { useFormik } from 'formik';
import AllowUseNavigate, { navigate } from '../../../../Navigate';
import { adminSimuladorVeActions } from '../../../../../actions/admin/simuladorVe.actions';
import { isNullOrEmpty } from '../../../../../helpers';

const FormEdit = ({
  produto = {},
  fornecedores = [],
  grupoMateriais = [],
  send = (produto) => { },
  ...props
}) => {
  const formik = useFormik({
    initialValues: {
      codigo: "",
      nome: "",
      sku: "",
      multiploEmbalagem: null,
      multiploPallet: null,
      fornecedorId: "",
      grupoMaterialId: ""
    },
    validate: (values) => {
      const errors = {};
      if (!values.codigo) {
        errors.codigo = 'O Código é obrigatório';
      }
      if (!values.nome) {
        errors.nome = 'O Nome é obrigatório';
      }
      if (!values.sku) {
        errors.sku = 'O SKU é obrigatório';
      }
      return errors;
    },
    onSubmit: (values) => {
      let formattedValues = values;
      if (isNullOrEmpty(formattedValues.fornecedorId))
        formattedValues = { ...formattedValues, fornecedorId: fornecedores?.[0]?.id, grupoMaterialId: grupoMateriais?.[0]?.id }

      send(formattedValues);
    },
  });

  useEffect(() => {
    formik.setValues({
      codigo: produto.codigo ?? "",
      nome: produto.nome ?? '',
      sku: produto.sku ?? "",
      multiploEmbalagem: produto.multiploEmbalagem ?? "",
      multiploPallet: produto.multiploPallet ?? "",
      fornecedorId: produto.fornecedorId ?? "",
      grupoMaterialId: produto.grupoMaterialId ?? ""
    });
  }, [produto]);

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <Stack spacing={2}>
        <FormControl fullWidth error={formik.touched.codigo && Boolean(formik.errors.codigo)}>
          <TextField
            id="codigo"
            name="codigo"
            label="Código"
            variant="standard"
            type="text"
            value={formik.values["codigo"]}
            onChange={formik.handleChange}
          />
          {formik.touched.codigo && <FormHelperText>{formik.errors.codigo}</FormHelperText>}
        </FormControl>

        <FormControl fullWidth error={formik.touched.nome && Boolean(formik.errors.nome)}>
          <TextField
            id="nome"
            name="nome"
            label="Nome"
            variant="standard"
            type="text"
            value={formik.values.nome}
            onChange={formik.handleChange}
          />
          {formik.touched.nome && <FormHelperText>{formik.errors.nome}</FormHelperText>}
        </FormControl>

        <FormControl fullWidth error={formik.touched.sku && Boolean(formik.errors.sku)}>
          <TextField
            id="sku"
            name="sku"
            label="SKU"
            variant="standard"
            type="text"
            value={formik.values.sku}
            onChange={formik.handleChange}
          />
          {formik.touched.sku && <FormHelperText>{formik.errors.sku}</FormHelperText>}
        </FormControl>

        <FormControl fullWidth error={formik.touched.multiploEmbalagem && Boolean(formik.errors.multiploEmbalagem)}>
          <TextField
            id="multiploEmbalagem"
            name="multiploEmbalagem"
            label="Múltiplo Embalagem"
            variant="standard"
            type="number"
            value={formik.values.multiploEmbalagem}
            onChange={formik.handleChange}
          />
          {formik.touched.multiploEmbalagem && <FormHelperText>{formik.errors.multiploEmbalagem}</FormHelperText>}
        </FormControl>

        <FormControl fullWidth error={formik.touched.multiploPallet && Boolean(formik.errors.multiploPallet)}>
          <TextField
            id="multiploPallet"
            name="multiploPallet"
            label="Multiplo Pallet"
            variant="standard"
            type="number"
            value={formik.values.multiploPallet}
            onChange={formik.handleChange}
          />
          {formik.touched.multiploPallet && <FormHelperText>{formik.errors.multiploPallet}</FormHelperText>}
        </FormControl>

        {/* <FormControl error={formik.touched.fornecedorId && Boolean(formik.errors.fornecedorId)} variant="standard" fullWidth>
          <InputLabel id="fornecedor-label">Fornecedor</InputLabel>
          <Select
            labelId="fornecedor-label"
            id="fornecedorId"
            name="fornecedorId"
            aria-describedby="fornecedor-text"
            value={formik.values.fornecedorId}
            label="Fornecedor"
            required
            onChange={formik.handleChange}
          >
            {fornecedores.map((x, index) => (
              <MenuItem key={x.id} value={x.id} selected={index === 0}>{x.nome}</MenuItem>
            ))}
          </Select>
          <FormHelperText id="fornecedor-text">
            {formik.touched.fornecedorId && formik.errors.fornecedorId}
          </FormHelperText>
        </FormControl> */}

        {/* <FormControl error={formik.touched.grupoMaterialId && Boolean(formik.errors.grupoMaterialId)} variant="standard" fullWidth>
          <InputLabel id="grupo-material-label">Grupo Material</InputLabel>
          <Select
            labelId="grupo-material-label"
            id="grupoMaterialId"
            name="grupoMaterialId"
            aria-describedby="grupoMaterial-text"
            label="Grupo Material"
            required
            value={formik.values.grupoMaterialId}
            onChange={formik.handleChange}
          >
            {
              grupoMateriais.map((x) => (
                <MenuItem key={x.id} value={x.id}>{x.nome}</MenuItem>
              ))
            }
          </Select>
          <FormHelperText id="grupoMaterial-text">
            {formik.touched.grupoMaterialId && formik.errors.grupoMaterialId}
          </FormHelperText>
        </FormControl> */}

        <Button variant="contained" color="primary" fullWidth type="submit">
          Salvar
        </Button>
      </Stack>
    </Box>
  );
};

class AdminUsuarioEditPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.sendProduto = this.sendProduto.bind(this);
  }

  componentWillUnmount() {
    this.props.actions.clearProduto();
  }

  componentWillMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.ProductId !== undefined && params.ProductId !== '')
      this.props.actions.requestProduct(params.ProductId)

    this.props.actions.requestFornecedores();
    this.props.actions.requestGrupoMateriais();
  }

  sendProduto(produto) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.ProductId !== undefined && params.ProductId !== '')
      this.props.actions.updateProduto(params.ProductId, produto, navigate);
    else
      this.props.actions.saveProduto(produto, navigate);
  }

  render() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    return (
      <div className="card" style={{ borderRadius: 0 }}>
        <AllowUseNavigate />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.adminSimuladorVe.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={"/Admin-SimuladorVendaExterna"}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/Admin-Produtos"}>
                Produtos
              </Link>
              <Typography color="text.primary">{(params.UserId !== undefined && params.UserId !== '') ? "Editar" : "Novo"}</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <FormEdit
            produto={this.props.adminSimuladorVe.produto ?? {}}
            fornecedores={this.props.adminSimuladorVe.fornecedores}
            grupoMateriais={this.props.adminSimuladorVe.grupoMaterial}
            send={this.sendProduto}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        //...adminUserActions,
        // ...adminModuloActions,
        // ...adminRoleActions,
        // ...adminTerritoryActions,
        // ...stockActions,
        ...adminSimuladorVeActions,
      }, dispatch),
    };
  }
)(AdminUsuarioEditPage);
