/* eslint-disable no-unused-vars */
import React from "react";
import { Routes, Route } from "react-router-dom";

import { AdminLayout } from "../components/AdminLayout";
import { Layout } from "../components/Layout";
import { LayoutSimulador } from "../components/LayoutSimulador";

import PrivateRoute from "../components/PrivateRoute";

// ======================= PAGINAS ADMINISTRADOR ======================== //
import AdminPrivateRoute from "../components/AdminPrivateRoute";
import AdminUsuarioHomePage from "../components/pages/admin/Usuario/AdminUsuarioHomePage";
import AdminJobs from "../components/pages/admin/Rotinas/AdminJobsPage";
import AdminUsuarioEditPage from "../components/pages/admin/Usuario/AdminUsuarioEditPage";
import AdminModuloHomePage from "../components/pages/admin/Modulo/AdminModuloHomePage";
import AdminModuloEditPage from "../components/pages/admin/Modulo/AdminModuloEditPage";
import AdminWhitespacePage from "../components/pages/admin/Whitespace/AdminWhitespacePage";
import AdminWhitespaceSafraEditPage from "../components/pages/admin/Whitespace/Safras/AdminSafraEdit";
import AdminWhitespaceSafraPage from "../components/pages/admin/Whitespace/Safras/AdminSafraPage";
import AdminWhitespaceCultureEditPage from "../components/pages/admin/Whitespace/Cultures/AdminCultureEdit";
import AdminWhitespaceCulturePage from "../components/pages/admin/Whitespace/Cultures/AdminCulturePage";
import AdminWhitespaceAccountPage from "../components/pages/admin/Whitespace/Accounts/AdminAccountPage";
import AdminStockPage from "../components/pages/admin/Stock/AdminStockPage";
import AdminEditStockPage from "../components/pages/admin/Stock/AdminStockEdit";
import AdminProdutoPage from "../components/pages/admin/SimuladorVendaExterna/Produtos/AdminSimuladorVeHomePage";
import AdminSimuladorVeEditPage from "../components/pages/admin/SimuladorVendaExterna/Produtos/AdminSimuladorVeEditPage";
import AdminSimuladorVeCreateFornecedorPage from "../components/pages/admin/SimuladorVendaExterna/Fornecedores/AdminSimuladorVeCreateFornecedor";
import AdminSimuladorVePage from "../components/pages/admin/SimuladorVendaExterna/AdminSimuladorVePage";
import AdminFornecedorPage from "../components/pages/admin/SimuladorVendaExterna/Fornecedores/AdminSimuladorVeHomePage";
import AdminDisponibilidadePage from "../components/pages/admin/SimuladorVendaExterna/Precos/AdminSimuladorVeHomePage";
import AdminSimuladorVeCreateDisponibilidadePage from "../components/pages/admin/SimuladorVendaExterna/Precos/AdminSimuladorVeCreatePreco";
import AdminGrupoMaterialPage from "../components/pages/admin/SimuladorVendaExterna/GrupoMaterial/AdminSimuladorVeHomePage";
import AdminGrupoMaterialEditPage from "../components/pages/admin/SimuladorVendaExterna/GrupoMaterial/AdminSimuladorVeCreateGrupoMaterial";
import AdminEncargosPage from "../components/pages/admin/SimuladorVendaExterna/Encargos/AdminSimuladorVeHomePage";
import AdminEncargoEditPage from "../components/pages/admin/SimuladorVendaExterna/Encargos/AdminSimuladorVeCreateEncargo";
import AdminSimuladorVeEditDisponibilidadePage from "../components/pages/admin/SimuladorVendaExterna/Precos/AdminSimuladorVeEditPreco";

// ======================= PAGINAS MODULOS ======================== //
import LoginPage from "../components/pages/login/LoginPage";
import LogoutPage from "../components/pages/login/LogoutPage";
import CallbackPage from "../components/pages/login/CallbackPage";
import HomePage from "../components/pages/home/HomePage";
import SimulatorPage from "../components/pages/simulator/SimulatorPage";
import SimulatorEditPage from "../components/pages/simulator/SimulatorEditPage";
import SimulatorHomePage from "../components/pages/simulator/SimulatorHomePage";
import EnrichmentPage from "../components/pages/enrichment/EnrichmentPage";
import WhiteSpacePage from "../components/pages/whitespace/WhiteSpacePage";
import WhiteSpaceView from "../components/pages/whitespace/WhiteSpaceView";


import SimulatorHomePage2 from "../components/pages/simulator2/SimulatorHomePage";
import SimulatorEditPage2 from "../components/pages/simulator2/SimulatorEditPage";
import DesmembramentoPage from "../components/pages/simulator2/desmembramento/DesmembramentoPage";
import SimulatorClientePage2 from "../components/pages/simulator2/SimulatorClientePage";
import ViewCenario2 from "../components/pages/simulator2/ViewCenario";
import CompararCenario2 from "../components/pages/simulator2/CompararCenario";
import ViewCenarioDetails2 from "../components/pages/simulator2/ViewCenarioDetails";
// import WorkflowPage2 from "../components/pages/simulator2/WorkflowPage";

import SimulatorHomePageVe from "../components/pages/simulatorVendaExterna/SimulatorHomePage";
import SimulatorPageVe from "../components/pages/simulatorVendaExterna/SimulatorFormPage";
import SimulatorViewPageVe from "../components/pages/simulatorVendaExterna/SimulatorViewPage";
import SimulatorClientePageVe from "../components/pages/simulatorVendaExterna/SimulatorClientePage";

// import { connect } from "react-redux";
// import { history } from "../helpers";

import SimulatorClientePage from "../components/pages/simulator/SimulatorClientePage";
import ViewCenario from "../components/pages/simulator/ViewCenario";
import { getAppInsights } from "./TelemetryService";
import TelemetryProvider from "./telemetry-provider";
import CompararCenario from "../components/pages/simulator/CompararCenario";
import ViewCenarioDetails from "../components/pages/simulator/ViewCenarioDetails";
import WorkflowPage from "../components/pages/simulator/WorkflowPage";
import StockPage from "../components/pages/stock/StockPage";
import WorkFlowPage2 from "../components/pages/simulator2/WorkflowPage";
import LeaderApproval from "../components/pages/simulator2/cpa/LeaderApproval";
import OrdensDesmembramento from "../components/pages/admin/Desmembramento/AdminOrdensPage";
import DesmembramentoViewPage from "../components/pages/simulator2/desmembramento/DesmembramentoViewPage";
import AdminVeCampaignForm from "../components/pages/admin/SimuladorVendaExterna/Campanhas/AdminVeCampaignForm";
import AdminVeCampaignPage from "../components/pages/admin/SimuladorVendaExterna/Campanhas/AdminVeCampaignPage";

const pagesSimulador2 = [
  { path: 'Simulador2', element: <SimulatorHomePage2 /> },
  { path: "Simulador2/MinhasSimulacoesCliente", element: <SimulatorClientePage2 /> },
  { path: "Simulador2/Simulador-Cliente-Edit", element: <SimulatorEditPage2 /> },
  { path: "Simulador2/Simulador-Cliente", element: <SimulatorEditPage2 /> },
  { path: "Simulador2/Cenario", element: <ViewCenario2 /> },
  { path: "Simulador2/CompararCenarioCliente", element: <CompararCenario2 /> },
  { path: "Simulador2/CenarioDetails", element: <ViewCenarioDetails2 /> },
  { path: "Simulador2/Desmembramento", element: <DesmembramentoPage /> },
  { path: "Simulador2/DesmembramentoView", element: <DesmembramentoViewPage /> },
  // { path: "Simulador2/Workflow", element: <WorkFlowPage2 /> },
]

const pagesSimuladorVe = [
  { path: 'SimuladorVe', element: <SimulatorHomePageVe /> },
  { path: "SimuladorVe/Simulador-Cliente-Edit", element: <SimulatorPageVe /> },
  { path: "SimuladorVe/Simulador-Cliente", element: <SimulatorPageVe /> },
  { path: "SimuladorVe/MinhasSimulacoesCliente", element: <SimulatorClientePageVe /> },
  { path: "SimuladorVe/Cenario", element: <SimulatorViewPageVe /> },
]

export default function App() {
  let appInsights = null;

  return (
    <TelemetryProvider
      instrumentationKey={process.env.REACT_APP_INSTRUMENTATION_KEY}
      after={() => {
        appInsights = getAppInsights();
      }}
    >
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Layout>
                <HomePage />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route path={"/"} element={<PrivateRoute outlet={true} parentModulo={"Simulador2"} />}>
          <Route path={""} element={<LayoutSimulador outlet={true} />}>
            {pagesSimulador2.map(x => <Route
              key={x.path}
              path={x.path}
              element={x.element}
            />
            )}
          </Route>
        </Route>

        <Route path={"/"} element={<PrivateRoute outlet={true} parentModulo={"SimuladorVe"} />}>
          <Route path={""} element={<LayoutSimulador outlet={true} />}>
            {pagesSimuladorVe.map(x => <Route
              key={x.path}
              path={x.path}
              element={x.element}
            />
            )}
          </Route>
        </Route>

        <Route
          path="/Admin-Modulo"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminModuloHomePage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />

        <Route
          path="/Admin-Modulo-Edit"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminModuloEditPage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/Admin-Modulo-Create"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminModuloEditPage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/Admin"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminUsuarioHomePage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/Admin-User-Edit"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminUsuarioEditPage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/Admin-User-Create"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminUsuarioEditPage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />

        <Route
          path="/Simulador"
          element={
            <PrivateRoute parentModulo={"Simulador"}>
              <LayoutSimulador>
                <SimulatorHomePage />
              </LayoutSimulador>
            </PrivateRoute>
          }
        />

        <Route
          path="/MinhasSimulacoesCliente"
          element={
            <PrivateRoute parentModulo={"Simulador"}>
              <LayoutSimulador>
                <SimulatorClientePage />
              </LayoutSimulador>
            </PrivateRoute>
          }
        />

        <Route
          path="/CompararCenarioCliente"
          element={
            <PrivateRoute parentModulo={"Simulador"}>
              <LayoutSimulador>
                <CompararCenario />
              </LayoutSimulador>
            </PrivateRoute>
          }
        />

        <Route
          path="/Simulador-Cliente"
          element={
            <PrivateRoute parentModulo={"Simulador"}>
              <LayoutSimulador>
                <SimulatorPage />
              </LayoutSimulador>
            </PrivateRoute>
          }
        />
        <Route
          path="/Whitespace"
          element={
            <PrivateRoute parentModulo={"Simulador"}>
              <LayoutSimulador>
                <WhiteSpacePage />
              </LayoutSimulador>
            </PrivateRoute>
          }
        />
        <Route
          path="/WhitespaceView"
          element={
            <PrivateRoute parentModulo={"Simulador"}>
              <LayoutSimulador>
                <WhiteSpaceView />
              </LayoutSimulador>
            </PrivateRoute>
          }
        />

        <Route
          path="/Simulador-Cliente-Edit"
          element={
            <PrivateRoute parentModulo={"Simulador"}>
              <LayoutSimulador>
                <SimulatorEditPage />
              </LayoutSimulador>
            </PrivateRoute>
          }
        />

        <Route
          path="/Admin-Whitespace"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminWhitespacePage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />

        <Route
          path="/Admin-Safra"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminWhitespaceSafraPage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />

        <Route
          path="/Admin-Safra-Edit"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminWhitespaceSafraEditPage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />

        <Route
          path="/Admin-Culture"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminWhitespaceCulturePage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />

        <Route
          path="/Admin-Culture-Edit"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminWhitespaceCultureEditPage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />

        <Route
          path="/Admin-Account"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminWhitespaceAccountPage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />

        <Route
          path="/Admin-Jobs"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminJobs />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />

        <Route
          path="/Admin-Stock"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminStockPage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />

        <Route
          path="/Admin-Period-Form"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminEditStockPage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />

        <Route
          path="/Admin-Desmembramento"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <OrdensDesmembramento />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />

        <Route
          path="/Admin-CampaignVe"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminVeCampaignPage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />

        <Route
          path="/Admin-CampaignVe-Form"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminVeCampaignForm />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />

        <Route
          path="/Enrichment"
          element={
            <PrivateRoute parentModulo={"Enrichment"}>
              <Layout>
                <EnrichmentPage />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/Cenario"
          element={
            <PrivateRoute parentModulo={"Simulador"}>
              <LayoutSimulador>
                <ViewCenario />
              </LayoutSimulador>
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/Cenario-Formulario"
          element={
            <PrivateRoute>
              <LayoutSimulador>
                <FormularioEditPage />
              </LayoutSimulador>
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/CenarioDetails"
          element={
            <PrivateRoute parentModulo={"Workflow"}>
              <LayoutSimulador>
                <ViewCenarioDetails />
              </LayoutSimulador>
            </PrivateRoute>
          }
        />
        <Route
          path="/Workflow"
          element={
            <PrivateRoute parentModulo={"Workflow"}>
              <LayoutSimulador>
                <WorkflowPage />
              </LayoutSimulador>
            </PrivateRoute>
          }
        />
        <Route
          path="/Workflow2"
          element={
            <PrivateRoute parentModulo={"Workflow2"}>
              <LayoutSimulador>
                <WorkFlowPage2 />
              </LayoutSimulador>
            </PrivateRoute>
          }
        />
        <Route
          path="/CenarioDetails2"
          element={
            <PrivateRoute parentModulo={"Workflow2"}>
              <LayoutSimulador>
                <ViewCenarioDetails2 />
              </LayoutSimulador>
            </PrivateRoute>
          }
        />
        <Route
          path="/Cpa"
          element={
            <PrivateRoute parentModulo={"Workflow2"}>
              <LayoutSimulador>
                <LeaderApproval />
              </LayoutSimulador>
            </PrivateRoute>
          }
        />

        <Route
          path="/Estoque"
          element={
            <PrivateRoute parentModulo={"Estoque"}>
              <LayoutSimulador>
                <StockPage />
              </LayoutSimulador>
            </PrivateRoute>
          }
        />

        <Route
          path="/login"
          element={
            <LoginPage />
          }
        />
        <Route
          path="/logout"
          element={
            <Layout>
              <LogoutPage />
            </Layout>
          }
        />
        <Route
          path="/callback"
          element={
            <Layout>
              <CallbackPage />
            </Layout>
          }
        />
        <Route
          path="/Admin-Produtos"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminProdutoPage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/Admin-Produto-Edit"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminSimuladorVeEditPage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/Admin-Create-Product"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminSimuladorVeEditPage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/Admin-Fornecedores"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminFornecedorPage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/Admin-SimuladorVendaExterna"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminSimuladorVePage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/Admin-Fornecedor-Create"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminSimuladorVeCreateFornecedorPage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/Admin-Fornecedor-Edit"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminSimuladorVeCreateFornecedorPage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/Admin-Precos"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminDisponibilidadePage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/Admin-Precos-Create"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminSimuladorVeCreateDisponibilidadePage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/Admin-Precos-Edit"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminSimuladorVeEditDisponibilidadePage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/Admin-GrupoMaterial"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminGrupoMaterialPage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/Admin-GrupoMaterial-Create"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminGrupoMaterialEditPage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/Admin-GrupoMaterial-Edit"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminGrupoMaterialEditPage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/Admin-Encargos"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminEncargosPage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/Admin-Encargo-Create"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminEncargoEditPage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/Admin-Encargo-Edit"
          element={
            <AdminPrivateRoute>
              <AdminLayout>
                <AdminEncargoEditPage />
              </AdminLayout>
            </AdminPrivateRoute>
          }
        />

        {/* <Route path="*" element={<NotFoundPage />} /> */}
      </Routes>
    </TelemetryProvider>
  );
}
