import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";

export default function ActionDialogButton({
  message = "",
  title = "",
  label = "Justificativa*",
  validInput = false,
  validFunction = (motivo = "") => { return motivo.replaceAll(" ", "").length < 20 },
  helperText = (motivo = "") => { return `Quantidade de Caracteres: ${motivo.replaceAll(" ", "").length} de 20`; },
  onClick = (motivo = "") => { },
  cancelText = "Cancelar",
  sendText = "Enviar",
  ButtonNode = Button,
  children,
  defaultMotivo = "",
  ...props }) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [motivo, setMotivo] = React.useState(defaultMotivo ?? "");

  const handleChangeMotivo = (e) => {
    setMotivo(e.target.value);
  }

  const handleClickOpenDialog = (e) => {
    setMotivo(defaultMotivo ?? "");
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setMotivo("");
  };

  const handleClickSend = () => {
    if (!validInput) {
      setOpenDialog(true);
      onClick(motivo);
    } else {
      if (!validFunction(motivo)) {
        onClick(motivo);
        setOpenDialog(false);
      }
    }
  }

  return (
    <>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
          {validInput ?
            <TextField
              autoFocus
              value={motivo}
              onChange={handleChangeMotivo}
              margin="dense"
              id="name"
              label={label}
              fullWidth
              variant="standard"
              error={validFunction(motivo)}
              helperText={validFunction(motivo) ? helperText(motivo) : ""}
            />
            : <TextField
              autoFocus
              value={motivo}
              onChange={handleChangeMotivo}
              margin="dense"
              id="name"
              label={label}
              fullWidth
              variant="standard"
            />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{cancelText}</Button>
          <Button onClick={handleClickSend} variant="contained">{sendText}</Button>
        </DialogActions>
      </Dialog>
      <ButtonNode {...props} onClick={handleClickOpenDialog}>
        {children}
      </ButtonNode>
    </>
  );
}