import React, { Component } from "react";
import { connect } from "react-redux";

import userManager from "../../../util/userManager";
import PropTypes from "prop-types";
import { Box, createTheme, CssBaseline, Grid, Paper, ThemeProvider, Typography } from "@mui/material";
import CortevaLogo from "../../../assets/svg/CortevaLogo/CortevaLogo";
import CortevaTextLogo from "../../../assets/svg/CortevaLogo/CortevaTextLogo";

class LogoutPage extends Component {
  componentDidMount() {
    userManager.signoutRedirect();
  }

  render() {
    const theme = createTheme();
    return (
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage:
                "url(https://assets.corteva.com/is/image/Corteva/IMG-climate_hero_2732x1820-Global:Wide)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ m: 15 }}>
                <CortevaLogo sx={{ fontSize: "2.5rem", color: "var(--cort-blue)" }} />
                <CortevaTextLogo sx={{ fontSize: "2rem", marginLeft: "0.5rem", width: "auto", color: "black" }} />
              </Box>

              <Typography
                component="h1"
                variant="h5"
                sx={{ color: "var(--cort-blue)", fontWeight: "bold" }}
              >
                Customer 360
              </Typography>
              <Box sx={{ mt: 1 }}>
                <Typography
                  fullWidth
                  sx={{ mt: 3, mb: 2 }}
                >
                  Desconectando...
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}

LogoutPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(LogoutPage);
